@import "../../mixins";

#translator-relay > article {
    .relay-audios {
        .relay-container {
            padding: 10pt;
            .translator_label {
                font-weight: bold;
            }
            select {
                background: #ffffff;
                padding: 5pt;
            }
            video {
                height: 60px;
                width: 100%;
            }
        }
    }
}
