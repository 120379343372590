#catchit_game_area
    position: relative
    width: 100%
    height: 100%
    background: url("../game_assets/catch_it/game_bg.jpg")
    background-size: cover
    background-position: bottom center

    .puff
        position: absolute
        div
            position: absolute
            border-radius: 50%
            background: #fff
            opacity: 1
            top: 0
            left: 0
            transform: translate(0, 0)
            animation-fill-mode: forwards
            animation-timing-function: ease-out
            filter: blur(1px)
            &.puff1
                width: 30px
                height: 30px
                animation-name: puff1
                animation-duration: 500ms
            &.puff2
                width: 20px
                height: 20px
                animation-name: puff2
                animation-duration: 300ms
            &.puff3
                width: 25px
                height: 25px
                animation-name: puff3
                animation-duration: 550ms
            &.puff4
                width: 27px
                height: 27px
                animation-name: puff4
                animation-duration: 430ms
            &.puff5
                width: 22px
                height: 22px
                animation-name: puff5
                animation-duration: 260ms
            &.puff6
                width: 28px
                height: 28px
                animation-name: puff6
                animation-duration: 320ms
            &.puff7
                width: 23px
                height: 20px
                animation-name: puff7
                animation-duration: 440ms
            &.puff8
                width: 26px
                height: 23px
                animation-name: puff8
                animation-duration: 290ms
            &.puff9
                width: 20px
                height: 26px
                animation-name: puff9
                animation-duration: 360ms
            &.puff10
                width: 30px
                height: 26px
                animation-name: puff10
                animation-duration: 540ms

    @keyframes puff1
        0%
            opacity: 1
        100%
            transform: translate(50px, -50px) scale(2.2)
            opacity: 0
            filter: blur(4px)

    @keyframes puff2
        0%
            opacity: 1
        100%
            transform: translate(-40px, -60px) scale(2.4)
            opacity: 0
            filter: blur(4px)

    @keyframes puff3
        0%
            opacity: 1
        100%
            transform: translate(30px, -40px) scale(2.3)
            opacity: 0
            filter: blur(4px)

    @keyframes puff4
        0%
            opacity: 1
        100%
            transform: translate(20px, -50px) scale(2.6)
            opacity: 0
            filter: blur(4px)

    @keyframes puff5
        0%
            opacity: 1
        100%
            transform: translate(-30px, -55px) scale(2)
            opacity: 0
            filter: blur(4px)

    @keyframes puff6
        0%
            opacity: 1
        100%
            transform: translate(36px, -48px) scale(2.7)
            opacity: 0
            filter: blur(4px)

    @keyframes puff7
        0%
            opacity: 1
        100%
            transform: translate(-44px, -70px) scale(2.4)
            opacity: 0
            filter: blur(4px)

    @keyframes puff8
        0%
            opacity: 1
        100%
            transform: translate(40px, -65px) scale(2.6)
            opacity: 0
            filter: blur(4px)

    @keyframes puff9
        0%
            opacity: 1
        100%
            transform: translate(-23px, -47px) scale(2.4)
            opacity: 0
            filter: blur(4px)

    @keyframes puff10
        0%
            opacity: 1
        100%
            transform: translate(20px, -52px) scale(2.7)
            opacity: 0
            filter: blur(4px)

    .catchit_cont
        .rohoznik_logo
            position: absolute
            top: 0
            left: 30pt
            z-index: 5
            width: 150px
            img
                width: 100%
        //display: none

    .start_content
        position: absolute
        z-index: 10
        width: 100%
        height: 100%
        font-size: 40px
        background: rgba(255,255,255,0.7)
        .start_inner
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            text-align: center
            width: 90%
            max-width: 600px
            .infotext
                font-size: 20px
                padding: 20pt
            .start_btn
                padding: 5pt 20pt
                border-radius: 5pt
                cursor: pointer
                display: inline-block
                font-weight: bold

    .final_content
        display: none
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background: #ffffff
        padding: 20px 40px
        border-radius: 5px
        .final_text
            font-weight: bold
            font-size: 35px
            text-align: center
            background-color: transparent !important

    .title
        color: #2cb570
        font-size: 47px
        text-transform: uppercase
        font-weight: bold
        padding: 15px 0

    .text
        padding-top: 20px
        color: #2795ca
        font-size: 35px

    .points_legend
        position: absolute
        bottom: 0px
        right: 5px
        span
            font-size: 30px
            font-weight: bold
        img
            vertical-align: middle
            width: 60px
            max-height: 70px
            transform-origin: bottom center
            &.mentol
                width: 40px
                position: relative
                top: -5px
                transform: scale(1)
            &.meteor
                width: 40px
                position: relative
                top: -10px
                transform: scale(1)

    .bottom_ground
        position: absolute
        bottom: 0
        height: 80px
        width: 100%
        z-index: 4
        background: url("../game_assets/catch_it/ground.jpg")

    .catcher
        position: absolute
        bottom: 80px
        left: 0
        width: 200px
        height: 55px
        z-index: 5
        img
            width: 100%
            display: block
            position: absolute
            top: 0
            left: 0
            z-index: 1
            backface-visibility: hidden
            &.truck
                transform: rotateY(0)

    .controls_cont
        //position: absolute
        //bottom: 10px
        //right: 10px
        color: #ffffff
        padding: 5px 30px
        flex: 1 1 100%
        div
            cursor: pointer
            position: relative
            text-align: center
            margin: 0 10px
            font-size: 30px
            max-width: 100px
            border-radius: 5pt

    .timer
        //position: absolute
        //bottom: 15px
        //left: -15px
        //transform: skew(-25deg)
        color: #ffffff
        font-size: 20px
        font-weight: bold
        z-index: 4
        padding: 5px 30px
        span
            display: inline-block
            //transform: skew(25deg)
            font-size: 26px

    .collect
        z-index: 4
        position: relative
        color: #ffffff
        font-size: 24px
        font-weight: bold
        text-align: center
        padding: 5px 30px
        span.p_cont
            height: 100%
            span
                margin: 0 5pt
                flex: 0 0 auto

    .game_rules
        width: 100%
        height: 650px
        background: rgba(255, 255, 255, 0.7)
        border-radius: 5px
        padding: 20px 85px
        margin-bottom: 20px

    .clickable
        max-height: 340px

    .game_content
        display: none
        margin: 0 auto
        width: 920px
        padding-top: 180px

    .game_area
        position: relative
        margin: 0 auto
        width: 920px
        height: 350px
        background: rgba(255, 255, 255, 0.7)
        border-radius: 5px

    .info_text
        display: none
        position: absolute
        background: rgba(255, 255, 255, 0.9)
        padding: 20px
        border-radius: 5px
        z-index: 10
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 920px
        height: 350px

    .box
        position: absolute
        top: 20px
        width: 188px
        height: 300px

        img
            width: 100%
            position: absolute
            bottom: 0

    .box1
        left: 94px

    .box2
        left: 370px

    .box3
        left: 645px

    .fall_container
        position: absolute
        width: 100%
        padding-top: 0
        height: 100%
        overflow: hidden

        img
            position: absolute
            width: 10%
            &.wrong
                width: 12%
