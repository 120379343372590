/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

@import "../../mixins";

#issues-details > article {
    fieldset {
        padding: 0;
        margin: dp(12) 0;
        border-radius: dp(3);
        border: 0;
        background-color: #eee;

        legend {
            padding: 0 dp(8);
            margin-left: dp(8);
            font-size: 85%;
            color: #777;
        }

        .selectAgenda,
        .selectSpeaker {
            width: 100%;
        }

        input[type="text"],
        textarea,
        select {
            resize: none;
            font-size: 110%;
            border: 0;
            padding: dp(4) dp(16) dp(12) dp(16);
        }

        &.focus {
            //border-color: #777;
            /*legend {
              color: #777;
            }*/
            input[type="text"],
            textarea {
                font-weight: bold;
                color: #333;
            }
        }
    }

    #issues-answer {
        display: none;
        border: 0;
        font-size: 110%;
        padding: 0;
        margin: dp(12) 0;
        .issues-answer {
            padding: dp(4) dp(16) dp(12) dp(16);
        }
    }

    .check-cont {
        margin: 10pt 0;
    }

    label {
        cursor: pointer;
    }
}
