/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#profile > article {
    fieldset {
        padding: 0;
        margin: dp(12) 0;
        border-radius: dp(3);
        border: 0;
        background-color: #eee;

        legend {
            padding: 0 dp(8);
            margin-left: dp(8);
            font-size: 85%;
            color: #777;
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        select,
        textarea {
            resize: none;
            font-size: 110%;
            border: 0;
            padding: dp(4) dp(16) dp(12) dp(16);
        }

        select {
            margin: 0 dp(8) 0 0;
        }

        &.focus {
            //border-color: #777;
            //background-color: #f9f9f9;
            /*legend {
              color: #777;
            }*/
            input[type="text"],
            input[type="email"],
            input[type="tel"],
            textarea {
                font-weight: bold;
                color: #333;
            }
        }
    }

    .change-pass {
        flex-grow: 0;
        margin: 10px 0;
    }

    .item {
        .info {
            padding: 0 dp(16);
        }
    }

    label {
        cursor: pointer;
    }

    input[type="file"] {
        display: none;
    }

    .avatar-wrapper {
        position: relative;
        margin: dp(8) auto;

        input {
            display: none;
        }

        .avatar {
            border: dp(4) solid #999;
        }

        .upload,
        .remove {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: dp(48);
            height: dp(48);
            border-radius: 50%;
            background-color: #777;
            color: white;
            //border: dp(3) solid white;
            @media (pointer: fine) {
                &:hover {
                    background-color: #555;
                }
            }

            &:active {
                background-color: #555;
            }
        }

        .upload {
            right: 0;
            bottom: 0;
        }

        .remove {
            left: 0;
            top: 0;
        }

        figcaption {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 400%;
        }
    }

    .slogan {
        font-family: "Times New Roman", Times, serif;
        font-style: italic;
        margin-top: dp(8);

        .data-slogan {
            padding: 0 dp(8);
            font-size: 115%;
        }
    }

    .listing {
        line-height: 2;

        .fa-fw {
            margin-left: dp(4);
            margin-right: dp(10);
        }
    }

    .privacy {
        padding: dp(8) dp(16);

        label {
            font-size: 110%;
            padding: dp(6) dp(8);

            &:not(:last-child) {
                border-bottom: dp(1) solid #ccc;
            }
        }

        .true {
            color: green;
        }

        .false {
            color: red;
        }

        input[type="checkbox"] {
            display: none;

            & ~ .true {
                display: none;
            }

            &:checked {
                & ~ .true {
                    display: inline-block;
                }

                & ~ .false {
                    display: none;
                }
            }
        }
    }
}
