/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#qrcode > article {
    .qrcode {
        margin: 0 auto;
        display: block;
        max-width: 256px;
        img {
            width: 100%;
        }
    }
}
