/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

@import "../../mixins";

#unity3d {

    article {
        .title {
            padding-bottom: 10pt;
        }
    }

    .unity3dList {
        tr td {
            cursor: pointer;
        }

        button {
            font-size: 70%;
            padding: 5px;
            height: auto;
        }

        .activeUnity3d {
            cursor: pointer;
        }

        .activeBtn {
            color: #0f0;
        }

        .inactiveBtn {
            color: #f00;
        }
    }

    .no-result {
        display: none;
        margin: dp(32) 0;
        text-align: center;
        font-size: 110%;
    }
}
