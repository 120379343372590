@import "../../mixins";

#translator > article {
    .color-primary {
        font-weight: bold;
    }

    .item {
        .playbtn {
            display: inline-block;
            font-size: 20pt;
        }
        .stopbtn {
            display: none;
            font-size: 20pt;
        }

        .vol-settings {
            font-size: 20pt;
        }
    }

    .item.publishing {
        background: #3cb430;
        color: #ffffff;
        .playbtn {
            display: none;
        }
        .stopbtn {
            display: inline-block;
        }
    }

    .data-name {
        padding: 0 dp(16);
    }
}
