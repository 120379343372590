@import "../../mixins";

#attendees-details > article {
    .avatar {
        margin: dp(8) auto;
    }

    .listing {
        line-height: 2;

        .data {
            margin-right: dp(15);
        }

        > * {
            flex: 0 0 auto;
        }
    }

    .social {
        > * {
            flex: 0 0 auto;
        }
    }

    .item {
        order: 1;

        .info {
            padding: 0 dp(12);
        }

        .chevron {
            padding: 0 dp(4);
        }

        .header {
            .location {
                margin-left: dp(16);
            }
        }
    }
}
