@import "../../mixins";

#roomlist > article {
    .qrcode {
        margin: 0 auto;
        display: block;
        max-width: 256px;
        img {
            width: 100%;
        }
    }
}
