/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

@import "../../mixins";

.edit_question {
    display: none;
    position: fixed;
    width: 90%;
    background: #fff;
    max-height: 90%;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    textarea {
        resize: none;
        font-size: 90%;
        height: 120px;
        border: 1px solid #cecece;
        width: 100%;
        padding: dp(4) dp(16) dp(12) dp(16);
        background: #ffffff;
    }
}

#moderate, #landing .landing_elements .moderate_cont > article {
    .moderateList {
        //position: relative;
    }

    .item {
        padding: 5pt;
        margin: 10.5pt;
        .vote-num {
            padding: 0 10pt;
        }

        .note_cont {
            padding: 10pt 10pt 10pt 0;
            color: black;
            .note-title {
                font-style: italic;
            }
            .data-note {
                font-size: 100% !important;
            }
        }

        .answer_cont {
            padding: 0 10pt 10pt 0;
            color: black;
            .answer-title {
                font-style: italic;
            }
            .data-answer {
                font-size: 100% !important;
            }
        }

        .info {
            margin: 0 dp(12);
            position: relative;
            .edit_btn {
                position: absolute;
                top: 0;
                right: 0;
            }
            textarea {
                resize: none;
                font-size: 90%;
                height: 200px;
                border: 1px solid #cecece;
                width: 100%;
                padding: dp(4) dp(16) dp(12) dp(16);
                background: #ffffff;
            }

            .question-title {
                font-style: italic;
                padding-top: 10pt;
            }
        }

        /*&.edited {
            position: fixed;
            width: 90%;
            background: #fff;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }*/

        &:last-child {
            border-bottom: dp(1) solid rgba(0, 0, 0, 0.15);
        }

        &.approved {
            background-color: rgba(156, 192, 156, 0.52);
        }

        &.denied {
            background-color: rgba(179, 0, 0, 0.18);
        }

        .approve-btn, .deny-btn, .return-btn, .save_issue_mod_btn, .cancel_issue_mod_btn, .approve_mod_btn {
            background: #ffffff;
            &:hover {
                background: #cbcbcb;
            }
        }
    }

    .filter-all, .filter-approved, .filter-denied, .filter-waiting {
        &.bg-primary {
            color: #ffffff;
        }
    }

    .no-result {
        display: none;
        margin: dp(32) 0;
        text-align: center;
        font-size: 110%;
    }
}
