@import "../../mixins";

#soundchannels > article {
    .color-primary {
        font-weight: bold;
    }

    #wowzaSoundchannel {
        display: none;
        width: 0 !important;
        height: 0 !important;
    }

    .item.publishing {
        background: #3cb430;
        color: #ffffff !important;
        i {
            color: #ffffff !important;
        }
    }

    .item.available {
        i {
            color: #52b445;
        }
    }

    .data-name {
        padding: 0 dp(16);
    }
}
