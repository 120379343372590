/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/

@import "../../mixins";

#channels {
    .color-primary {
        font-weight: bold;
    }

    .data-name {
        padding: 0 dp(16);
    }
}
