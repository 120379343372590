/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

@import "../../mixins";

#landing {
    .landing_elements {
        #messageboard {
            .container {
                display: flex;
                flex-direction: column-reverse;
                margin: 0;
                padding: 0;
                .message_container {
                    flex: 0 0 100px;
                    width: 100%;
                    padding: 5pt;
                    .item {
                        display: none;
                    }

                    form {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        fieldset {
                            flex: 1 1 auto;
                        }
                        p {
                            flex: 0 0 auto;
                        }
                    }
                }
                .messages_container {
                    flex: 0 1 auto;
                    overflow-y: auto;
                }
            }
        }
    }
}

#messageboard > article {
    fieldset {
        padding: 0;
        margin: dp(12) 0;
        border-radius: dp(3);
        border: 0;
        background-color: #eee;

        legend {
            padding: 0 dp(8);
            margin-left: dp(8);
            font-size: 85%;
            color: #777;
        }

        input[type="text"],
        textarea {
            resize: none;
            font-size: 110%;
            border: 0;
            padding: dp(4) dp(16) dp(12) dp(16);
        }

        &.focus {
            //border-color: #777;
            /*legend {
              color: #777;
            }*/
            input[type="text"],
            textarea {
                font-weight: bold;
                color: #333;
            }
        }
    }

    label {
        cursor: pointer;
    }

    .container {
        &.transparent {
            background: transparent !important;
            box-shadow: none;
            padding: 0 !important;
            .message_container {
                background: rgba(255,255,255,0.6);
                padding: 5pt;
                border-radius: 5pt;
            }
            .messages_container {
                .message_item {
                    background: rgba(255, 255, 255, 0.9);
                    padding: 5pt;
                    border-radius: 5pt;
                    border-bottom: 0;
                    margin-top: 5pt;
                }
            }
        }
        .messages_container {
            width: 100%;
            transition: height 200ms linear;

            .message_cover {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            .messages {
                width: 100%;

                .message_item {
                    .image_msg {
                        text-align: center;

                        img {
                            max-height: 300px;
                        }
                    }

                    .data-sender {
                        font-weight: bold;
                        font-size: 13pt;
                    }

                    //word-break: break-all;
                    display: block;
                    width: 100%;
                    padding: 10pt;
                    border-bottom: 1px solid #cecece;

                    &.noborder {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

#messageboard .messageboard_modal {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.9);
    display: none;
    .buttons, .gallery {
        text-align: center;
        width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .gallery {
        overflow-y: scroll;
        max-height: 90%;
    }

    .close {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    .galimage {
        display: inline-block;
        max-width: 33.33333%;
        padding: 10px;
        img {
            max-width: 100%;
        }
    }
}
