/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#speakers-details > article {
    .avatar {
        margin: dp(8) auto;
    }

    .slogan {
        //font-family: "Times New Roman", Times, serif;
        font-style: italic;
        margin-top: dp(8);

        .data-slogan {
            padding: 0 dp(8);
            font-size: 90%;
        }
    }

    .listing {
        line-height: 2;

        .data {
            margin-right: dp(15);
        }

        > * {
            flex: 0 0 auto;
        }
    }

    .social {
        > * {
            flex: 0 0 auto;
        }
    }

    .item {
        order: 1;

        .info {
            padding: 0 dp(12);
        }

        .chevron {
            padding: 0 dp(4);
        }

        .favorite {

        }

        .data-title {
            .underparent {
                padding: 5pt 0 0 5pt;
                display: inline-block;
            }
        }

        .header {
            .location {
                margin-left: dp(16);
            }
        }
    }

    .padding-top-5 {
        padding-top: 5px;
    }

    .padding-left-1 {
        padding-left: 1px;

    }
}
