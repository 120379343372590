@import "../../mixins";

/*
#broadcast {
        z-index: 20000;
}

*/
#broadcast > article {
    .color-primary {
        font-weight: bold;
    }

    .data-name {
        padding: 0 dp(16);
    }
    /*
    .broadcastcontainer {
        position: relative;
        text-align: center;
        color: white;
        width: 100%;
        height: 100%;
        background-color: white;
        background-size: cover;
        background-position: center;
    }

    .broadcasttitle {
        position: absolute;
    }
    */
}

/*
.videostream {
    position: relative;
}
*/


#runawaycover {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    z-index: 1000;
    display: none;
}

#runawaycover .broadcasttitle {
    color: black;
    font-size: 30pt;
    text-align: center;
    position: absolute;
    width: 100%;
    text-shadow: 0 0 5px white;
}

@media only screen and (max-width: 768px) {
    #runawaycover .broadcasttitle {
        top: 20%;
        left: 50%;
        transform: translate(-50%, -10%);
        font-size: 20pt;
        padding: 15px;
    }
}

@media only screen and (min-width: 768px) {
    #runawaycover .broadcasttitle {
        top: 20%;
        left: 50%;
        transform: translate(-50%, -20%);
        font-size: 40pt;
        padding: 100px;
    }
}

.stream {
    display: none;
    z-index: 1000;
    margin: 0;
    padding: 0;
    transition: all 0.5s linear;
    flex-basis: 100%;
}

.stream.min {
    position: fixed;
    width: 178px !important;
    height: 100px !important;
    top: auto !important;
    left: auto !important;
    right: 20px !important;
    bottom: 10px !important;
    .reactions {
        display: none;
    }
}

.video-js, .my-video-dimensions {
    width: 100% !important;
    height: 100% !important;
}

#my-video-bg_html5_api,
#my-video_html5_api {
    width: 100% !important;
    /* height: 100% !important; */
    /* object-fit: cover; */
}
#my-video-bg_html5_api {
    height: 100% !important;
    object-fit: cover;
}


#playerElement2,
#playerElement {
    width: 100%;
    height:100%;
    position: relative;
}

#playerElementBackground {
    width: 100%;
    height:100%;
    position: absolute;
    display: none;
    overflow: hidden!important;
}

.video-js .vjs-big-play-button {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
