@import "../../mixins";

#favorite-agenda > article {
    #favorite-agendaList {
        .item {
            //order: 1;
            position: relative;
            .info {
                padding: 0 dp(12);
            }

            .chevron {
                padding: 0 dp(4);
            }

            .now,
            .expired {
                display: none;
            }

            .header {
                font-size: 95%;
                // .now {
                //   text-transform: uppercase;
                // }
                .rooms {
                    margin-left: dp(16);
                }
            }

            &.now {
                background-color: #333;
                color: #fff;
                border-color: #444;
                margin: 0 -2vmax;
                padding: dp(14) dp(34);
                @media screen and (min-width: 780px) {
                    margin: 0 -4vmax;
                }
                //background-color: #f9f9f9;
                //order: 0;
                .text-secondary {
                    //color: #555;
                    color: inherit;
                }

                .date {
                    display: none;
                }

                .now {
                    display: block;
                }
            }

            &.ended {
                //color: #999;
                /*.text-secondary {
                  color: inherit;
                }*/
                .date {
                    display: none;
                }

                .expired {
                    display: block;
                }
            }

            section {
                display: block;
            }

            &.breaking {
                pointer-events: none;

                .chevron,
                .star {
                    color: transparent;
                }
            }

            .title-short {
                font-size: 140%;
            }

            .title-long {
                font-size: 120%;
            }

            .title-extra {
                font-size: 110%;
            }
        }
    }

    .date-wrapper {
        display: block;
        position: relative;
        padding: 0;

        .mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-color: white;
            font-size: 120%;
            font-weight: bold;
            padding: 0 dp(16);

            .content {
                text-align: center;
            }
        }

        @media (pointer: fine) {
            &:hover .mask {
                background-color: #f9f9f9;
            }
        }

        &:active .mask {
            background-color: #f9f9f9;
        }
    }

    .date-selector {
        -webkit-appearance: none;
        width: 100%;
        border: none;
        cursor: pointer;
        padding: dp(16) 0;
    }
}
