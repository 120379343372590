@import "../../mixins";

#modal {
    &.small_popup {
        background-color: transparent;
    }
    article {
        overflow-y: auto !important;

        &.small_popup {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(255, 255, 255, 0.9);
            border-radius: 5pt;
            box-shadow: 0 0 10px rgba(0,0,0,0.4);
            padding: 20pt;
            max-width: 90%;
            max-height: 80%;
            min-width: 280px;
        }
    }
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.95);
    color: inherit;
    .image {
        max-width: 100%;
        display: none;
        &.shown {
            display: inline-block;
        }
        img {
            max-width: 90%;
            max-height: 500px;
        }
    }
    &.dark {
        background-color: rgba(30, 30, 30, 0.95);
        color: #ffffff;

        .btn {
            color: #ffffff !important;
            border: 2px solid #ffffff !important;
        }

        &.small_popup {
            background-color: transparent;
            color: #555;
            .btn {
                color: #555 !important;
                border: 2px solid #555 !important;
            }
        }
        article {
            &.small_popup {
                background: rgba(30, 30, 30, 0.95);
                color: #ffffff;
                .btn {
                    color: #ffffff !important;
                    border: 2px solid #ffffff !important;
                }
            }
        }
    }
}
