/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

.full_booking {
    padding: 10pt 0;
}

#bookings-details > article {

    .user-bookins {
        text-align: center;
        .ub_item {
            padding-top: 10pt;
            span {
                display: block;
            }
        }
    }

    .buttons {
    }

    .item {
        border: 0;

        h3 {
            font-weight: bold;
            text-align: center;
            margin-bottom: dp(12);
        }

        .data-booking-info {
            padding-top: 10pt;
        }

        label {
            animation-duration: 0.2s;

            &:after {
                content: attr(data-result) !important;
                color: inherit;
                font-weight: bold;
            }

            &.marked {
                color: white;
                background-color: darkgreen;
                border-color: darkgreen;
            }
        }
    }

    .bookings-radio {
        label {
            backface-visibility: hidden;
            padding: dp(8) dp(16) dp(8) dp(8);
            margin: dp(4) 0;
            border-radius: dp(5);
            border: dp(1) solid #999;
            cursor: pointer;

            input,
            .checked {
                display: none;
            }

            i {
                flex: 0 0 auto;
            }

            span {
                padding-left: dp(8);
                font-size: 115%;
            }

            @media (pointer: fine) {
                &:hover {
                    background-color: #f9f9f9;
                }
            }

            &.state-checked,
            &.marked {
                .unckecked {
                    display: none;
                }

                .checked {
                    display: initial;
                }
            }

            &.state-checked {
                border-color: black;
                background-color: #333;
                color: white;
                @media (pointer: fine) {
                    &:hover {
                        background-color: #595959;
                    }
                }
            }
        }
    }
}

#bookings > article {
    .item {
        order: 1;

        .info {
            padding: 0 dp(12);
        }

        .chevron {
            padding: 0 dp(4);
        }

        .header {
            .location {
                margin-left: dp(16);
            }
        }
    }
}
