@import "../../mixins";

#gdpr {
    z-index: 1000000;

    .closebutton {
        display: none !important;
    }

    .backbutton {
        display: none !important;
    }
}

#gdpr-docs {
    z-index: 1000000;
    display: none;
}

#gdpr > article {
    .item {
        border-bottom: none;
    }
}
