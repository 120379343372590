/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

@import "../../mixins";

#issues > article, #issues-vote > article  {
    .item {
        .info {
            margin: 0 dp(12);
        }

        .vote-up-btn, .vote-down-btn {
            padding: 0 10pt;
            font-size: 14pt;
        }
    }

    .no-result {
        display: none;
        margin: dp(32) 0;
        text-align: center;
        font-size: 110%;
    }
}
