/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

@import "../../mixins";

#notifications > article {
    .item {
        .info {
            margin: 0 dp(12);
        }

        /*&:first-child {
          border-top: dp(1) solid rgba(0, 0, 0, 0.15);
        }*/
    }

    .no-result {
        display: none;
        margin: dp(32) 0;
        text-align: center;
        font-size: 110%;
    }
}
