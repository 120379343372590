/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#surveys-details > article {
    .item {
        border: 0;

        h3 {
            font-weight: bold;
            text-align: center;
            margin-bottom: dp(12);
        }

        label {
            animation-duration: 0.2s;
        }

        .data-description {
            display: none;
            padding-bottom: dp(12);
            text-align: justify;
        }
    }

    .survey-star {
        text-align: center;

        .fa-star {
            color: #333;
            cursor: pointer;
        }
    }

    .survey-text {
        textarea {
            width: 100%;
            resize: none;
            border-radius: dp(5);
            border: dp(1) solid #999;
            padding: dp(8);
        }
    }

    .survey-radio,
    .survey-check {
        label {
            backface-visibility: hidden;
            padding: dp(8) dp(16) dp(8) dp(8);
            margin: dp(4) 0;
            border-radius: dp(5);
            border: dp(1) solid #999;
            cursor: pointer;

            input,
            .checked {
                display: none;
            }

            i {
                flex: 0 0 auto;
            }

            span {
                padding-left: dp(8);
                font-size: 115%;
            }

            @media (pointer: fine) {
                &:hover {
                    background-color: #f9f9f9;
                }
            }

            &.state-checked {
                border-color: black;
                background-color: #333;
                color: white;

                .unckecked {
                    display: none;
                }

                .checked {
                    display: initial;
                }

                @media (pointer: fine) {
                    &:hover {
                        background-color: #595959;
                    }
                }
            }
        }
    }

    .done {
        display: none;
        margin-top: dp(32);
        text-align: center;
        font-size: 110%;
    }

    .survey_description {
        text-align: justify;
    }


    .survey_title h3 {
        font-weight: bold;
        text-align: center;
        margin-bottom: dp(12);

    }
}

.data-thank-you {
    text-align: center;
}
