@import "../../mixins";

#ondemandvideos-details > article {
    .color-primary {
        font-weight: bold;
    }

    .data-name {
        padding: 0 dp(16);
    }

    .item {
        a {
            color: #555;
        }
    }
}

#ondemandvideos-details {

}

.ondemandvideo_viewer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 108;
    background: #000000;
    flex-direction: column;
    .close_video {
        background: #ffffff;
        padding: 10pt;
        text-align: center;
    }
    #ondemandvideo_viewer {
        width: 100%;
        height: 100%;
        position: relative;
        #ondemandvideo {
            width: 100% !important;
            height: 100% !important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            video {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}
