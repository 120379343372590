@import "../../mixins";
#agenda-subdetails > article,
#agenda-subsubdetails > article,
#agenda-details > article {

    .join-workshop {
        display: none;
    }

    .childrenagenda {
        .item {
            //order: 1;
            position: relative;
            .info {
                padding: 0 dp(12);
            }

            .chevron {
                padding: 0 dp(4);
            }

            .now,
            .expired {
                display: none;
            }

            .header {
                font-size: 95%;
                // .now {
                //   text-transform: uppercase;
                // }
                .rooms {
                    margin-left: dp(16);
                }
            }

            &.now {
                background-color: #333;
                color: #fff;
                border-color: #444;
                margin: 0 -2vmax;
                @media screen and (min-width: 780px) {
                    margin: 0 -4vmax;
                }
                //background-color: #f9f9f9;
                //order: 0;
                .text-secondary {
                    //color: #555;
                    color: inherit;
                }

                .date {
                    display: none;
                }

                .now {
                    display: block;
                }
            }

            &.ended {
                //color: #999;
                /*.text-secondary {
                  color: inherit;
                }*/
                .date {
                    display: none;
                }

                .expired {
                    display: block;
                }
            }

            section {
                display: block;
            }

            &.breaking {
                pointer-events: none;

                .chevron,
                .star {
                    color: transparent;
                }
            }

            .title-short {
                font-size: 140%;
            }

            .title-long {
                font-size: 120%;
            }

            .title-extra {
                font-size: 110%;
            }
        }
    }

    .item {
        position: relative;
        .favorite_agenda {
            margin: dp(4) dp(8) dp(4) 0;
            font-size: 18px;
            cursor: pointer;
            flex-grow: 0;
            padding-right: dp(12);
        }
        header {
            line-height: 2;
            padding: 0;
            width: 100%;
        }

        header .text-left {
            width: 100%;
        }

        .info {
            padding: 0 dp(16);
        }

        h4.docs-title {
            display: flex;

            i {
                padding-top: 2px;
            }
        }

        /* .maplink {
          cursor: pointer;

          @media (pointer: fine) {
            &:hover {
              text-decoration: underline;
            }
          }
        } */
    }
}
