@import "../../mixins";

#unity3d-details {
    display: none;
    z-index: 1002;

    #unityContainer {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 100;
    }

    .poster_viewer {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 106;
        flex-direction: column;
        .close_poster {
            background: #ffffff;
            padding: 10pt;
            text-align: center;
        }
        #poster_viewer {
            width: 100%;
            height: 100%;
            background: #ffffff;
        }
    }

    .stand_viewer {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 107;
        flex-direction: column;
        .close_stand {
            background: #ffffff;
            padding: 10pt;
            text-align: center;
        }
        #stand_viewer {
            width: 100%;
            height: 100%;
            background: #ffffff;
        }
    }

    .video_viewer {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 108;
        background: #000000;
        flex-direction: column;
        .close_video {
            background: #ffffff;
            padding: 10pt;
            text-align: center;
        }
        #video_viewer {
            width: 100%;
            height: 100%;
            position: relative;
            #unity_video {
                width: 100% !important;
                height: 100% !important;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                video {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
    }

    .loader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 101;
        background-image: url("../unity/loader_fisu_bg.jpg");
        background-position: center;
        background-size: cover;
        .loader_logo {
            max-width: 50%;
        }
        .loader-center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            max-width: 500px;
            text-align: center;
            .text {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 16pt;
                padding: 20pt 0;
                text-align: center;
                color: #000000;
            }

            .line {
                background-color: #b0b0b0;
                width: 100%;
                height: 5px;

                .percentage-line {
                    background-color: #000000;
                    width: 50%;
                    height: 100%;
                }
            }
        }
    }

    .row {
        color: #000000;

        &.divider {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1.5vw 0;
            padding-top: 0.25vw;

            .line {
                background-color: #004C74;
                height: 3px;
                width: 7vw;
            }
        }

        &.title {
            font-size: 3vw;
            text-align: center;
            text-transform: uppercase;
        }

        &.sub-title {
            font-size: 1.6vw;
            text-align: center;
            text-transform: uppercase;
        }

        &.description {
            font-size: 1.25vw;
            line-height: calc(1.25vw * 1.2);
            text-align: center;
            text-align-last: center;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            .button {
                width: auto;
                padding: 1vw 5vw;
                font-size: 1.5vw;
                text-transform: uppercase;
            }
        }
    }

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0.8);
    }
    .button {
        padding: 0.8vw 5vw;
        background-color: #004C74;
        color: white;
        text-align: center;
        cursor: pointer;
    }

    .tutorial {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 102;
        .background {
            //display: none;
        }
        .center {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
        }

        #next-tutorial {
            width: 14vw;
        }

        #end-tutorial {
            background-color: transparent;
            padding: 0 !important;
            color: #000000;
        }

        .row {
            &.divider {
                &.lower {
                    margin-top: 0.375vw;
                    margin-bottom: 0.875vw;
                }

                &.small-top-margin {
                    margin-top: 1.25vw;
                }

                &.device-marker {
                    font-size: 1.2vw;
                    color: #004C74;
                    margin-top: 0.75vw;
                }
            }
        }

        .slides {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            height: 19.1vw;
            width: 32vw;
            overflow: hidden;
            margin-bottom: 1.25vw;

            .red-text {
                color: #004C74;
            }

            .bold-text {
                color: #004C74;
            }

            .slide {
                position: absolute;
                width: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: 125ms all linear;
                opacity: 1;

                &.out-left {
                    left: -100%;
                    opacity: 0;
                }

                &.out-right {
                    left: 100%;
                    opacity: 0;
                }

                img {
                    height: 6vw;
                    max-height: 100%;
                    max-width: 14vw;
                }

                .icon-container {
                    margin-top: 2vw;
                }

                .half-size {
                    img {
                        height: 3vw;
                    }
                }
            }
        }
        .dots {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            align-items: center;

            .dot {
                width: 0.75vw;
                height: 0.75vw;
                background-color: white;
                margin: 1vw;
                cursor: pointer;

                &.completed {
                    background-color: #004C74;
                }
            }
        }

        .buttons {
            margin-top: 2.5vw;
        }
    }

    .end3d {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 103;
        .center {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

            .buttons {
                margin-top: 2vw;

                .button {
                    margin: 0 2vw;
                    font-size: 1.15vw;
                    width: 10vw;
                    padding: 0.9vw 1vw;
                    text-align: center;

                    &.no {
                        background-color: white;
                        color: black;
                    }
                }
            }
        }
    }

    .open_new_window, .reactivate {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 104;
        .center {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

            .buttons {
                margin-top: 2vw;

                .button {
                    margin: 0 2vw;
                    font-size: 1.15vw;
                    width: 10vw;
                    padding: 0.9vw 1vw;
                    text-align: center;

                    &.no {
                        background-color: white;
                        color: black;
                    }
                }
            }
        }
    }

    .reactivate {
        text-align: center;
        img {
            height: 6vw;
            max-height: 100%;
            max-width: 14vw;
        }
    }

    .help {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(0%);
        width: 50%;
        background-color: rgba(255,255,255,0.8);
        //display: flex;
        //height: 4.75vw;
        padding-top: 0.3vw;
        transition: 250ms all;
        z-index: 100;

        &.not-shown {
            transform: translateX(-50%) translateY(100%);
        }

        .left, .right {
            position: relative;
            color: white;

            .button {
                text-transform: uppercase;
                padding: 0.5vw 1vw;
            }

            .text {
                text-transform: uppercase;
            }

            .icon {
                width: 2vw;
            }

            img {
                height: 60%;
            }
        }

        .right {
            width: 15%;

            .text {
                margin-right: 0.6vw;
            }
        }

        .left {
            width: 17%;

            .text {
                margin-left: 0.6vw;
            }
        }

        .left, .right {
            .top {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .center {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 80%;

                &:nth-child(3) {
                    height: 50%;
                }
            }

            .col {
                margin: 0 1vw;
            }

            &.ctext {
                .col {
                    height: 100%;
                    font-size: 14pt;
                    padding: 5pt;
                    color: #3C5B99;
                    font-weight: bold;
                }
            }
        }

        .col {
            height: 7vh;
            text-align: center;
            width: 100%;

            .top {
                height: 70%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .bottom {
                height: 30%;
                color: #000000;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75vw;
            }
        }
    }
}
