@use "sass:math";
@import "../../mixins";

#minimal_nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    > .container {
        height: 100%;
        position: relative;
        float: right;
        display: flex;
        margin-top: 0;
        max-width: dp(120);
        padding: 0;
        text-align: center;
        overflow-x: auto;
        overflow-y: auto;
        width: auto;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }
        &.top {
            float: none;
            width: 100%;
            height: 46px;
            max-width: none;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .wrapper {
            pointer-events: all;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 2px;
            flex: 0 0 auto;
            font-size: 90%;
            .data-icon {
                display: inline;
            }
            .data-img {
                display: none;
                max-width: 100%;
            }

            &.img_quick {
                .data-icon {
                    display: none;
                }
                .data-img {
                    display: block;
                }
            }

            .open-main {
                flex: 0 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 42px;
                height: 42px;
                background-color: none !important;
                margin: 0 auto;
                border: 0;
                cursor: pointer;
                position: relative;
                &.red {
                    color: #ff0000 !important;
                }
                @media (pointer: fine) {
                    &:hover {
                        color: white !important;
                    }
                }

                &.badge {
                    &:after {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: #f00;
                        border-radius: 50%;
                        top: 5pt;
                        right: 5pt;
                    }
                }

                &:active {
                    color: white !important;
                    /* background-color: #777 !important; */
                }
            }

            .data-icon {
                font-size: 20px;
            }

            .data-title {
                flex: 1 1 auto;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                color: black;
                word-break: break-word;
                hyphens: auto;
                padding: 1.2vmin 0 0 0;
            }

            .blinkingicon {
                animation: blinkingiconanimation_minimal 1s linear infinite;
            }

            .greensoundchannelsbutton {
                color: white!important;
                background-color: green !important;
            }

            @keyframes blinkingiconanimation_minimal {
                0% {background-color: transparent;}
                50% {background-color: #ED1C24;}
                100% {background-color: transparent;}
            }
        }
    }
}

.topAnimationDown {
    animation: minimalTopAnimationDown 0.25s linear infinite;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes minimalTopAnimationDown {
    0% {top: 60px;}
    100% {top: 120px;}
}

.topAnimationUp {
    animation: minimalTopAnimationUp 0.25s linear infinite;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes minimalTopAnimationUp {
    0% {top: 120px;}
    100% {top: 60px;}
}

#default_nav {
    background-color: #d9d9d9;
    border-top: 4px solid rgba(150, 150, 150, 1);
    &.floating {
        border-top: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        left: 100%;
        z-index: 1001;
        background-color: transparent;

        @media screen and (max-width: 1024px) {
            display: none !important;
        }

        &.elevated {
            //bottom: 60px;
        }
        &.higher {
            //bottom: 30px;
            &.elevated {
                //bottom: 90px;
            }
        }

        &.not-panel {
            top: auto;
            left: 0;
            bottom: 0;
            transform: none;
        }

        &.panel {
            &.opened {
                left: 50%;
                transform: translate(-50%, 0);
                top: auto;
                bottom: 0;
                .container {
                    .navplusbutton {
                        .open-main {
                            transform: rotate(45deg);
                            i {
                                display: block;
                            }
                        }
                    }
                }
            }
            @media screen and (min-width: 1024px) {
                min-width: 50%;
                transition: left 400ms linear;
                &.opened {
                    top: 50%;
                    left: 50%;
                    bottom: auto;
                    width: auto !important;
                    transform: translate(-50%, -50%);
                    border-radius: 20pt;

                    .container {
                        max-width: 100% !important;
                        .closemenu {
                            position: absolute;
                            right: -25px;
                            top: -25px;

                            .open-main {
                                width: 50px;
                                height: 50px;
                                transform: rotate(45deg);
                            }

                            .data-title {
                                display: none;
                            }
                        }
                    }
                }
                &.closing {
                    left: 100%;
                }
            }
        }
        .container {
            //justify-content: flex-end;
            margin: 0;
            width: 100%;
            max-width: 100%;
            padding: 0;
            .wrapper {
                .data-title {
                    text-shadow: 1px 1px 3px #000;
                }

                .open-main {
                    border: none;
                    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
                }
            }
        }
        &.opened {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            .container {
                justify-content: center;
                padding: 2vmin;
                .closemenu {
                    .open-main {
                        transform: rotate(45deg);
                    }
                }
            }
            width: 100%;
        }

        &.blur {
            -webkit-backdrop-filter: blur(10px);
            -moz-backdrop-filter: blur(10x);
            -ms-backdrop-filter: blur(10px);
            -o-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
        }
    }
    > .container {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 2vmin;
        max-width: dp(1280);
        text-align: center;
        overflow-x: auto;
        overflow-y: hidden;
        @media screen and (min-width: 1100px) {
            max-width: 85%;
        }
        @media screen and (orientation: landscape) and (min-width: 360px) {
            justify-content: center;
        }
        @media screen and (orientation: landscape) and (max-height: 680px-1px) {
            white-space: nowrap;
            /*flex-wrap: nowrap;*/
            max-width: none;
            margin: auto;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1vmin;
            flex: 0 0 percentage(math.div(1, 4));
            font-size: 90%;
            @media screen and (min-width: 680px) {
                font-size: inherit;
                flex: 0 0 percentage(math.div(1, 6));
            }
            @media screen and (min-width: 1024px) {
                font-size: inherit;
                flex: 0 0 percentage(math.div(1, 10));
            }
            @media screen and (orientation: landscape) and (max-height: 680px-1px) {
                flex: 0 0 dp(80);
                font-size: 75%;
                padding: 0 1vmin;
            }

            .data-icon {
                display: inline;
            }
            .data-img {
                display: none;
                max-width: 90%;
                border-radius: 50%;
            }

            &.img_normal {
                .data-icon {
                    display: none;
                }
                .data-img {
                    display: block;
                }
            }

            .open-main {
                flex: 0 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18vmin;
                height: 18vmin;
                background-color: whitesmoke;
                margin: 0 auto;
                border: dp(1) solid #ccc;
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                &.red {
                    color: #ff0000 !important;
                }
                @media (pointer: fine) {
                    &:hover {
                        color: white !important;
                        background-color: #777 !important;
                    }
                }

                &.badge {
                    &:after {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: #f00;
                        border-radius: 50%;
                        top: 5pt;
                        right: 5pt;
                    }
                }

                &:active {
                    color: white !important;
                    background-color: #777 !important;
                }

                /*
                &.shownewnoti {
                    color: red!important;
                    display: block;
                    border: 1px solid #70DB55;
                }
                */

                @media screen and (min-width: 360px) {
                    width: 15vmin;
                    height: 15vmin;
                }
                @media screen and (min-width: 680px) {
                    width: 10vmin;
                    height: 10vmin;
                }
                @media screen and (orientation: landscape) and (max-height: 680 px-1px) {
                    width: 15vmin;
                    height: 15vmin;
                }
            }

            .data-icon {
                font-size: 5vmin;
                @media screen and (min-width: 680px) {
                    font-size: 3vmin;
                }
                @media screen and (orientation: landscape) and (max-height: 680 px-1px) {
                    font-size: 5vmin;
                }
            }

            .data-title {
                flex: 1 1 auto;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                color: black;
                word-break: break-word;
                hyphens: auto;
                padding: 1.2vmin 0 0 0;
            }

            .blinkingicon {
                animation: blinkingiconanimation 1s linear infinite;
            }

            .greensoundchannelsbutton {
                color: white!important;
                background-color: green !important;
            }

            @keyframes blinkingiconanimationx {
                0% {background-color: #f5f5f5; color: #616161}
                50% {background-color: #ED1C24; color: #fff;}
                100% {background-color: #f5f5f5; color: #616161}
            }

            @keyframes blinkingiconanimation {
                0% {background-color: #f5f5f5;}
                50% {background-color: #ED1C24;}
                100% {background-color: #f5f5f5;}
            }
        }
    }
}

#landing > header > .landing_elements > #navplus {
    display: none;
    position: absolute;
    bottom: 0;
    width: auto;
    right: 0;

    .blur {
        -webkit-backdrop-filter: blur(15px);
        -moz-backdrop-filter: blur(15x);
        -ms-backdrop-filter: blur(15px);
        -o-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        background: rgba(255,255,255,0.2);
    }

    > .container {
        align-items: center;
        margin-bottom: 0;
        position: relative;
        z-index: 2000;
        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        padding: 2vmin;
        max-width: dp(1280);
        height: auto;
        bottom: 0;
        text-align: center;
        overflow-x: auto;
        overflow-y: hidden;
        @media screen and (min-width: 1100px) {
            max-width: 100%;
        }
        @media screen and (orientation: landscape) and (min-width: 360px) {
            justify-content: center;
        }
        @media screen and (orientation: landscape) and (max-height: 680px-1px) {
            white-space: nowrap;
            /*flex-wrap: nowrap;*/
            max-width: none;
            margin: auto;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1vmin;
            flex: 0 0 percentage(math.div(1, 4));
            font-size: 90%;
            @media screen and (min-width: 680px) {
                font-size: inherit;
                flex: 0 0 percentage(math.div(1, 6));
            }
            @media screen and (min-width: 1024px) {
                font-size: inherit;
                flex: 0 0 percentage(math.div(1, 10));
            }
            @media screen and (orientation: landscape) and (max-height: 680px-1px) {
                flex: 0 0 dp(80);
                font-size: 75%;
                padding: 0 1vmin;
            }

            .open-main {
                opacity: 1!important;
                flex: 0 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18vmin;
                height: 18vmin;
                background-color: whitesmoke;
                margin: 0 auto;
                /* border: dp(1) solid #ccc; */
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                &.red {
                    color: #ff0000 !important;
                }
                @media (pointer: fine) {
                    &:hover {
                        color: white !important;
                        background-color: #777 !important;
                    }
                }

                &.badge {
                    &:after {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: #f00;
                        border-radius: 50%;
                        top: 5pt;
                        right: 5pt;
                    }
                }

                &:active {
                    color: white !important;
                    background-color: #777 !important;
                }

                @media screen and (min-width: 360px) {
                    width: 15vmin;
                    height: 15vmin;
                }
                @media screen and (min-width: 680px) {
                    width: 10vmin;
                    height: 10vmin;
                }
                @media screen and (orientation: landscape) and (max-height: 680px-1px) {
                    width: 15vmin;
                    height: 15vmin;
                }
            }

            .data-icon {
                font-size: 5vmin;
                @media screen and (min-width: 680px) {
                    font-size: 3vmin;
                }
                @media screen and (orientation: landscape) and (max-height: 680px-1px) {
                    font-size: 5vmin;
                }
            }

            .data-title {
                flex: 1 1 auto;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                color: white;
                word-break: break-word;
                hyphens: auto;
                padding: 1.2vmin 0 0 0;
                /*text-shadow: 1px 1px 0 grey,
                -1px -1px 0 grey,
                1px -1px 0 grey,
                -1px  1px 0 grey,
                1px  1px 0 grey;
                */
            }

            .blinkingicon {
                animation: blinkingiconanimation 1s linear infinite;
            }

            .greensoundchannelsbutton {
                color: white!important;
                background-color: green;
            }

            @keyframes blinkingiconanimationx {
                0% {background-color: #f5f5f5; color: #616161}
                50% {background-color: #ED1C24; color: #fff;}
                100% {background-color: #f5f5f5; color: #616161}
            }

            @keyframes blinkingiconanimation {
                0% {background-color: #f5f5f5;}
                50% {background-color: #ED1C24;}
                100% {background-color: #f5f5f5;}
            }
        }
    }

    .open-main {
        border: 0!important;
    }
}

.blink_icon {
    animation: blinkingiconanimation2 1s linear infinite;
}

@keyframes blinkingiconanimation2 {
    0% {

    }
    50% {
        color: #ffffff;
        background-color: #17AEE8;
    }
    100% {

    }
}

.articlebgvideo {
    position: relative;
    overflow: hidden!important;
}

article {
    .outer {
        width: 100%;
        max-width: dp(780);
        margin: auto;
        height: 100%;
        position: relative;
        .closebutton, .backbutton {
            position: absolute;
            display: none;
            top: -15px;
            right: -15px;
            font-size: 14pt;
            border: 2px solid;
            border-radius: 50%;
            width: 30pt;
            height: 30pt;
            background: #fff !important;
            z-index: 102;
            cursor: pointer;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .backbutton {
            right: 30px;
        }
    }
    &.articlebgvideo {
        .outer {
            .closebutton, .backbutton {
                display: block;
                @media screen and (max-width: 780px-1px) {
                    display: none !important;
                }
            }
        }
    }
}

.containerbgvideo {
    z-index: 100;
    position: relative;
    max-height: 80%;
    overflow-y: auto !important;
    height: 100%;
    padding: 10pt;

    @media screen and (max-width: 780px-1px) {
        max-height: 100%;
    }
}

.scrollbarcontainerbgvideo {
    height: 100%;
    overflow-y: scroll;
    position: relative;
    padding: 1vmax;
}

.playerbgvideo {
    display: block;
    left: 0;
    top:0;
   /* top:-60px; */
    /*top: -60px;
    bottom: -60px;
    */
   /* height: 100% + calc(60px)!important; */
    border: 5px solid red;
    z-index: 10;
    position: relative;
}

.navplusbutton {

    border-top: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    left: 100%;
    z-index: 1001;
    background-color: transparent;

    @media screen and (max-width: 1024px) {
        display: none !important;
    }

    &.fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1002;
    }

    .data-title {
        position: absolute;
        text-orientation: upright;
        display: block;
        writing-mode: vertical-rl;
        padding: 0;
        text-transform: uppercase;
        left: 25pt;
        pointer-events: none;
        font-weight: bold;
        font-size: 14pt;
        background: transparent !important;
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 680px) {
            font-size: 11pt;
            left: 20pt;
        }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vmin;
    flex: 0 0 percentage(math.div(1, 4));
    font-size: 90%;
    @media screen and (min-width: 680px) {
        font-size: inherit;
        flex: 0 0 percentage(math.div(1, 6));
    }
    @media screen and (min-width: 1024px) {
        font-size: inherit;
        flex: 0 0 percentage(math.div(1, 10));
    }
    @media screen and (orientation: landscape) and (max-height: 680px-1px) {
        flex: 0 0 dp(80);
        font-size: 75%;
        padding: 0 1vmin;
    }

    .open-main {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18vmin;
        height: 18vmin;
        background-color: whitesmoke;
        margin: 0 auto;
        border: dp(1) solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        i {
            display: none;
        }

        &.blink_button {
            background-color: none !important;
            animation: blinkingiconanimation3 1s linear infinite !important;
        }

        &.red {
            color: #ff0000 !important;
        }

        @media (pointer: fine) {
            &:hover {
                color: white !important;
                background-color: #777 !important;
            }
        }

        &.badge {
            &:after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                background: #f00;
                border-radius: 50%;
                top: 5pt;
                right: 5pt;
            }
        }

        &:active {
            color: white !important;
            background-color: #777 !important;
        }

        /*
        &.shownewnoti {
            color: red!important;
            display: block;
            border: 1px solid #70DB55;
        }
        */
        @media screen and (min-width: 360px) {
            width: 15vmin;
            height: 15vmin;
        }
        @media screen and (min-width: 680px) {
            width: 13vmin;
            height: 13vmin;
        }
        @media screen and (orientation: landscape) and (max-height: 680px-1px) {
            width: 15vmin;
            height: 15vmin;
        }
    }

    .data-icon {
        font-size: 5vmin;
        @media screen and (min-width: 680px) {
            font-size: 3vmin;
        }
        @media screen and (orientation: landscape) and (max-height: 680px-1px) {
            font-size: 5vmin;
        }
    }

    .blinkingicon {
        animation: blinkingiconanimation 1s linear infinite;
    }

    .greensoundchannelsbutton {
        color: white !important;
        background-color: green !important;
    }

    @keyframes blinkingiconanimationx {
        0% {
            background-color: #f5f5f5;
            color: #616161
        }
        50% {
            background-color: #ED1C24;
            color: #fff;
        }
        100% {
            background-color: #f5f5f5;
            color: #616161
        }
    }
    @keyframes blinkingiconanimation {
        0% {
            background-color: #f5f5f5;
        }
        50% {
            background-color: #ED1C24;
        }
        100% {
            background-color: #f5f5f5;
        }
    }
}

#landing > #navmenuclassic_old {
    display: block;
    background-color: #c7c7c7;

    > .container {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 2vmin;
        max-width: dp(1280);
        text-align: center;
        overflow-x: auto;
        overflow-y: hidden;
        @media screen and (min-width: 1100px) {
            max-width: 85%;
        }
        @media screen and (orientation: landscape) and (min-width: 360px) {
            justify-content: center;
        }
        @media screen and (orientation: landscape) and (max-height: 680px-1px) {
            white-space: nowrap;
            /*flex-wrap: nowrap;*/
            max-width: none;
            margin: auto;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1vmin;
            flex: 0 0 percentage(math.div(1, 4));
            font-size: 90%;
            @media screen and (min-width: 680px) {
                font-size: inherit;
                flex: 0 0 percentage(math.div(1, 6));
            }
            @media screen and (min-width: 1024px) {
                font-size: inherit;
                flex: 0 0 percentage(math.div(1, 10));
            }
            @media screen and (orientation: landscape) and (max-height: 680px-1px) {
                flex: 0 0 dp(80);
                font-size: 75%;
                padding: 0 1vmin;
            }

            .open-main {
                flex: 0 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18vmin;
                height: 18vmin;
                background-color: whitesmoke;
                margin: 0 auto;
                border: dp(1) solid #ccc;
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                &.red {
                    color: #ff0000 !important;
                }
                @media (pointer: fine) {
                    &:hover {
                        color: white !important;
                        background-color: #777 !important;
                    }
                }

                &.badge {
                    &:after {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: #f00;
                        border-radius: 50%;
                        top: 5pt;
                        right: 5pt;
                    }
                }

                &:active {
                    color: white !important;
                    background-color: #777 !important;
                }

                /*
                &.shownewnoti {
                    color: red!important;
                    display: block;
                    border: 1px solid #70DB55;
                }
                */

                @media screen and (min-width: 360px) {
                    width: 15vmin;
                    height: 15vmin;
                }
                @media screen and (min-width: 680px) {
                    width: 10vmin;
                    height: 10vmin;
                }
                @media screen and (orientation: landscape) and (max-height: 680 px-1px) {
                    width: 15vmin;
                    height: 15vmin;
                }
            }

            .data-icon {
                font-size: 5vmin;
                @media screen and (min-width: 680px) {
                    font-size: 3vmin;
                }
                @media screen and (orientation: landscape) and (max-height: 680 px-1px) {
                    font-size: 5vmin;
                }
            }

            .data-title {
                flex: 1 1 auto;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                color: black;
                word-break: break-word;
                hyphens: auto;
                padding: 1.2vmin 0 0 0;
            }

            .blinkingicon {
                animation: blinkingiconanimation 1s linear infinite;
            }

            .greensoundchannelsbutton {
                color: white!important;
                background-color: green;
            }

            @keyframes blinkingiconanimationx {
                0% {background-color: #f5f5f5; color: #616161}
                50% {background-color: #ED1C24; color: #fff;}
                100% {background-color: #f5f5f5; color: #616161}
            }

            @keyframes blinkingiconanimation {
                0% {background-color: #f5f5f5;}
                50% {background-color: #ED1C24;}
                100% {background-color: #f5f5f5;}
            }
        }
    }
}

#landing > header > .landing_elements_xxx {
    position: absolute;
    top: 0;
}

.shownavlpusbutton_xx {
    width: auto!important;
    right: 0!important;
    border: 1px solid red;
}
