/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

@import "../../mixins";

#photos > article {

    .actions {
        margin-top: 10pt;
        display: flex;
        justify-content: space-between;
        .delete_bulk, .send_bulk {
            //display: none;
        }
        .add_gallery {
            display: flex;
            align-items: center;
        }
    }

    #photo_upload {
        display: none;
    }

    .container {
        position: relative;
    }

    .gallery_list {
        position: fixed;
        width: 500pt;
        max-width: 80%;
        max-height: 90%;
        display: none;
        flex-direction: column;
        background: #ffffff;
        padding: 10pt;
        border-radius: 5pt;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
        .close {
            .gallery-select-title {
                font-size: 14pt;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .items {
            overflow-y: auto;
            margin-top: 10pt;
            .gallery_item {
                position: relative;
                cursor: pointer;
                padding: 10pt 0;
                border-bottom: 1px solid #f5f5f5;
                font-size: 12pt;
                &.added {
                    &:after {
                        content: "\f00c";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 600;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                    }
                }
            }
        }
    }

    .upload_progress {
        padding: 1px;
        position: absolute;
        border-radius: 3px;
        width: 100%;
        top: 5px;
        display: none;
        left: 50%;
        transform: translateX(-50%);
        text-align: left;
        background: #fff;
        box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
        .progress-bar {
            height: 20px;
            border-radius: 3px;
            width: 0;
            box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
        }
        .status {
            top:3px;
            left:50%;
            position:absolute;
            display:inline-block;
            color: #000000;
        }
    }

    .photosList {
        display: flex;
        flex-wrap: wrap;
        .photoitem {
            flex: 1 1 25%;
            @media screen and (max-width: 768px) {
                flex: 1 1 33.33333%;
            }
            border-bottom: none;
            background: #f5f5f5;
            border-radius: 5pt;
            margin: 5pt;
            padding: 0;
            .user_photo {
                border-top-left-radius: 5pt;
                border-top-right-radius: 5pt;
                background-size: cover;
                background-position: center;
                height: 200px;
                &.no_bottom {
                    border-radius: 5pt;
                }
            }
            .bottom {
                padding: 10pt 5pt;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .vote-count-cont {
                    display: flex;
                    align-items: center;
                    text-align: right;
                    .vote-count {
                        margin-left: 5pt;
                    }
                }
            }
            &.selected {
                //.user_photo {
                //    border: 2px solid #3cb430;
                //}
                .bottom {
                    .select_image {
                        color: #ffffff;
                        background: #3cb430;
                        border: 2px solid #3cb430;
                    }
                }
            }
        }
    }

    .no-result {
        display: none;
        margin: dp(32) 0;
        text-align: center;
        font-size: 110%;
    }
}

.imageviewer {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: none;
    img {
        max-width: 90%;
        max-height: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
