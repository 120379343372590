/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#gallery-details > article {

    .gallery_title {
        text-align: center;
    }

    .gallery_description {
        text-align: center;
    }

    .items {

        display: flex;
        flex-wrap: wrap;

        .item {
            flex: 1 1 25%;
            @media screen and (max-width: 768px) {
                flex: 1 1 33.33333%;
            }
            border-bottom: none;
            background: #f5f5f5;
            border-radius: 5pt;
            margin: 5pt;
            padding: 0;
            .gallery_image {
                border-top-left-radius: 5pt;
                border-top-right-radius: 5pt;
                background-size: cover;
                background-position: center;
                height: 200px;
                cursor: pointer;
                &.no_bottom {
                    border-radius: 5pt;
                }
            }
            .bottom {
                padding: 10pt 5pt;
                display: flex;
                justify-content: space-between;
                .vote-count-cont {
                    display: flex;
                    align-items: center;
                    text-align: right;
                    .vote-count {
                        margin-left: 5pt;
                    }
                }
            }
        }
    }

}
