/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
*/

@import "../../mixins";

#namecards {
    z-index: 20000;
}

#namecards > article {

    .namecardscontainer {
        position: relative;
        text-align: center;
        color: white;
        width: 100%;
        height: 100%;
        background-color: #000;
        background-size: cover;
        background-position: center;
    }

    .namecardstitle {
        position: absolute;
    }

    .namecardsubstitle {
        position: absolute;
    }

}

@media only screen and (max-width: 768px) {
    #namecards > article {
        .namecardstitle {
            top: 10%;
            left: 50%;
            transform: translate(-50%, -10%);
            font-size: 40pt;
            padding: 0;
        }

        .namecardsubstitle {
            top: 40%;
            left: 50%;
            transform: translate(-50%, -10%);
            font-size: 100pt;
            padding: 0;
        }
    }
}

@media only screen and (min-width: 768px) {
    #namecards > article {

        .namecardstitle {
            top: 20%;
            left: 50%;
            transform: translate(-50%, -20%);
            font-size: 80pt;
            padding: 100px;
        }

        .namecardsubstitle {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -20%);
            font-size: 100pt;
            padding: 100px;
        }
    }
}
