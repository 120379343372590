/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

.container {
    width: 100%;
    max-width: dp(780);
    margin: auto;
}

div.main {
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: 1.4;
    background-color: #fff;
    &.hasfooter {
        bottom: 0;
    }
    @media screen and (min-width: 780px) {
        background-color: #e9e9e9;
        &.hasfooter {
            bottom: 54px;
        }
    }

    @media screen and (min-width: 1024px) {
        &.hasfooter {
            bottom: 74px;
        }
    }

    > header {
        display: block;
        position: relative;

        > section {
            display: block;
            height: dp(45);
            padding: 0 dp(8);
            @media screen and (min-width: 360px) {
                height: dp(60);
                padding: 0 dp(16);
            }
            @media screen and (orientation: landscape) and (max-height: 679px) {
                height: dp(50);
                padding: 0 dp(8);
            }
        }

        > .header-primary {
            color: #333;
            background-color: white;

            .logo-container {
                max-width: 40%;
                .logo {
                    max-height: 55px;
                    max-width: 100%;
                    height: auto;
                    display: block;
                }
            }

            .hamburger {
                display: block;
                color: inherit;
                padding: dp(10) 0 dp(10) dp(10);
                cursor: pointer;
                @media (pointer: fine) {
                    &:hover {
                        color: #777 !important;
                    }
                }

                &:active {
                    color: #777 !important;
                }
            }

            &.reverse .container {
                flex-direction: row-reverse;

                .text-left {
                    text-align: right;
                }

                .text-right {
                    text-align: left;
                }

                .hamburger {
                    padding: dp(10) dp(10) dp(10) 0;
                }
            }
        }

        > .header-secondary {
            color: #fff;
            background-color: grey;
        }

        > .toast {
            position: absolute;
            overflow: hidden;
            top: 0;
            right: 0;
            left: 0;
            background-color: #333;
            color: white;

            .status {
                margin-right: dp(8);
            }

            .close {
                cursor: pointer;
                padding-left: dp(16);
            }
        }

        .container {
            align-items: center;
            height: 100%;
            max-width: dp(1280);
            @media screen and (min-width: 1100px) {
                max-width: 85%;
            }

            img {
                height: dp(30);
                cursor: pointer;
                @media screen and (min-width: 360px) {
                    height: dp(40);
                }
                @media screen and (orientation: landscape) and (max-height: 680 px-1px) {
                    height: dp(30);
                }
            }

            > .text-center {
                padding: 0 dp(8);
            }
        }
    }

    > article:not(.info) {
        display: block;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;

        .container {
            overflow: hidden;
            background-color: white;
            @media screen and (min-width: 780px) {
                &.containerbgvideo {
                    background: rgba(255, 255, 255, 0.95);
                }
            }
            padding: 2vmax;
            margin-bottom: 100px;
            @media screen and (min-width: 780px) {
                margin-top: 4vmax;
                //margin-bottom: dp(60);
                //padding: 4vmax;
                border-radius: dp(15);
                box-shadow: 0 2px 5px -2px rgba(50, 50, 50, 0.5);
            }
        }
    }

    .scroll-top-wrapper {
        display: block;
        position: absolute;
        bottom: dp(10);
        left: 0;
        right: 0;
        text-align: center;
        pointer-events: none;

        > .scroll-top {
            pointer-events: auto;
            display: inline-block;
            font-weight: bold;
            background-color: rgba(50, 50, 50, 0.75);
            color: white;
            border-radius: dp(100);
            cursor: pointer;
            padding: dp(6) dp(16) dp(6) dp(8);
            @media (pointer: fine) {
                &:hover {
                    background-color: #555 !important;
                }
            }

            &:active {
                background-color: #555 !important;
            }
        }
    }
}

div.main.backdrop {
    background-color: rgba(0, 0, 0, 0.75);
}

div.main.system {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10vmin;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    z-index: 1002;

    .icon-main {
        font-size: 5em;
    }

    p,
    h1 {
        margin: 0;
    }

    h1 {
        font-weight: normal;
    }

    article.info {
        font-size: 110%;
        margin: auto;
        max-width: 80vmax;
    }
}

.item {
    padding: dp(14);
    text-decoration: none;
    border-bottom: dp(1) solid rgba(0, 0, 0, 0.15);

    &:last-child {
        border: 0;
    }

    &.link {
        cursor: pointer;
        @media (pointer: fine) {
            &:hover {
                background-color: #f9f9f9;
            }
        }

        &:active {
            background-color: #f9f9f9;
        }
    }
}

.closemainbutton {
    position: absolute;
    top: 10pt;
    right: 5pt;
    font-size: 30pt;
    border: 2px solid;
    border-radius: 50%;
    width: 30pt;
    height: 30pt;
    background: #fff !important;
    z-index: 102;
    cursor: pointer;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.avatar {
    display: block;
    overflow: hidden;
    width: dp(64);
    height: dp(64);
    margin: 0;
    background-color: #ccc;
    border-radius: 50%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.avatar--small {
    width: dp(48);
    height: dp(48);
}

.avatar--large {
    width: dp(96);
    height: dp(96);
}

.avatar--huge {
    width: dp(190);
    height: dp(190);
}

.disable-pe {
    pointer-events: none;
}

@media (print), (prefers-reduced-motion: reduce) {
    div.main.backdrop {
        display: none !important;
    }
}

.check-cont input {
    position: absolute;
    visibility: hidden;
}

.check-cont label {
    position: relative;
    padding-left: 25px;
    font-size: 105%;
    font-weight: 400;
    line-height: 16pt;
    display: block;
}

.check-cont label:before {
    content: '';
    width: 16pt;
    height: 16pt;
    background: #fff;
    border: 1.5pt solid #555;
    border-radius: 5px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.check-cont input[type="checkbox"].error + label:before {
    border: 1px solid #ff0000;
}

.check-cont input[type="checkbox"]:checked + label:after {
    content: '';
    width: 10pt;
    height: 10pt;
    background: #555;
    border-radius: 2px;
    position: absolute;
    left: 3pt;
    top: 3pt;
}

.attention_btn {
    position: absolute;
    z-index: 1000000;
    white-space: nowrap;
    background: #ffffff;
    border: 2px solid #000000;
    padding: 10pt;
    box-shadow: 0 0 10pt rgba(255, 0, 0, 0.5);
}
