#tetris {
    #tetris_game {

        position: relative;
        width: 100%;
        height: 100%;
        background: url("../game_assets/tetris/bg.jpg");
        background-size: cover;

        .final_content {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #ffffff;
            padding: 20px 40px;
            border-radius: 5px;
            .final_text {
                font-weight: bold;
                font-size: 35px;
                text-align: center;
                background-color: transparent !important;
            }
        }

        .start_content {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: 100%;
            font-size: 40px;
            background: rgba(255, 255, 255, 0.7);
            .start_inner {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                width: 90%;
                max-width: 600px;
                .infotext {
                    font-size: 20px;
                    padding: 20pt;
                }
                .start_btn {
                    padding: 5pt 20pt;
                    border-radius: 5pt;
                    cursor: pointer;
                    display: inline-block;
                    font-weight: bold;
                }
            }
        }

        .tetris_footer {
            position: relative;
            height: 70px;
            background: #555;
            color: #fff;
        }

        .blockrain-game-holder {
            position: relative;
            padding: 0;
            margin: 0;
            color: #ffffff;
            font-size: 18px;
            line-height: 140%;
        }

        /* Touch Controls */
        .blockrain-touch {
            position: absolute;
            width: 50px;
            height: 50px;
            display: block;
            border-radius: 100%;
            border: 1px solid white;
            background: rgba(255, 255, 255, 0.2);
            z-index: 10;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            line-height: 50px;
            text-align: center;

            &:active {
                background: rgba(255, 255, 255, 0.3);
            }
        }

        .blockrain-touch-left {
            left: -60px;
            bottom: 10px;

            &:after {
                content: "<";
            }
        }

        .blockrain-touch-right {
            right: -60px;
            bottom: 10px;

            &:after {
                content: ">";
            }
        }

        .blockrain-touch-rotate-left {
            left: -60px;
            bottom: 80px;

            &:after {
                content: "<o";
            }
        }

        .blockrain-touch-rotate-right {
            right: -60px;
            bottom: 80px;

            &:after {
                content: "o>";
            }
        }

        .blockrain-touch-drop {
            left: 50%;
            margin-left: -25px;
            bottom: -60px;

            &:after {
                content: ".";
            }
        }

        /* Buttons */
        .blockrain-btn {
            position: relative;
            display: inline-block;
            background: none;
            color: #ffffff;
            text-decoration: none;
            border: 2px solid #ffffff;
            padding: 15px 30px;
            font-size: 18px;
            text-align: center;
            cursor: default;

            &:before {
                position: absolute;
                content: "";
                top: 100%;
                left: 0px;
                bottom: 0px;
                right: 0px;
                border: 2px solid #ffffff;
                border-top: none;
                border-right: none;
                transition: all 0.2s ease;
            }

            &:after {
                position: absolute;
                content: "";
                top: 0px;
                left: 100%;
                bottom: 0px;
                right: 0px;
                border: 2px solid #ffffff;
                border-left: none;
                border-bottom: none;
                transition: all 0.2s ease;
            }

            &:hover::before {
                left: 5px;
                bottom: -8px;
                right: -8px;
            }

            &:hover::after {
                top: 5px;
                bottom: -8px;
                right: -8px;
            }
        }

        /* Score */
        .blockrain-score-holder {
            font-size: 30px;
            .blockrain-score {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 70px;
                .blockrain-score-num {
                    margin: 0 10pt;
                }
            }
        }

        /* Menu */

        .blockrain-game-over-holder {
            display: none !important;
        }

        .blockrain-start-holder, .blockrain-game-over-holder {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.8);
            text-align: center;
        }

        .blockrain-start, .blockrain-game-over {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
        }

        .blockrain-start-msg, .blockrain-game-over-msg {
            font-size: 18px;
            margin-bottom: 30px;
        }

        .tetris_cont {
            height: calc(100% - 70px);
            .control_arrow {
                color: #ffffff;
                font-size: 30px;
                cursor: pointer;
                text-align: center;
                border-radius: 5pt;
                padding: 10pt;
                max-width: 100px;
            }

            .game {
                max-width: 500px;
                height: 100%;
            }

            .left {
                cursor: pointer;
                text-align: right;
                height: 100%;
                padding: 10pt;
            }

            .right {
                cursor: pointer;
                height: 100%;
                padding: 10pt;
            }
        }
    }
}
