$ll: 60
$lt: 60
$lw: 45
$lh: 45
$perrow: 9
$perrow2: 5

#enigma
    background: #ffffff
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: none
    #game_area
        width: 100%
        height: 100%
        background-position: center
        background-size: cover
        .timer
            float: right

        .letter, .letter_space
            //cursor: none
            width: #{$lw}px
            height: #{$lh}px
            text-align: center
            line-height: #{$lw}px
            font-size: 20px
            border-radius: 50%
            background-color: rgba(255, 255, 255, 1)
            color: #d1212b
            border: 2px solid #d1212b
            position: absolute
            font-weight: bold
            user-select: none
            text-transform: uppercase
            @media (max-width: 480px)
                $lw: 35
                $lh: 35
                width: #{$lw}px
                height: #{$lh}px
                line-height: #{$lw}px
                font-size: 18px

        .letter.shakeit, .letter_space.shakeit
            animation: shakeit 0.6s cubic-bezier(.36, .07, .19, .97) both
            transform: translate3d(0, 0, 0)
            backface-visibility: hidden
            perspective: 1000px

        .letter.rotateletter
            animation: rotateLetter 1000ms linear infinite

        @keyframes rotateLetter
            0%
                transform: rotate(0)
            100%
                transform: rotate(360deg)

        @keyframes shakeit
            10%, 90%
                transform: translate3d(-1px, 0, 0)
            20%, 80%
                transform: translate3d(2px, 0, 0)
            30%, 50%, 70%
                transform: translate3d(-4px, 0, 0)
            40%, 60%
                transform: translate3d(4px, 0, 0)

        $row: 0
        $column: 0
        $row2: 0
        $column2: 0

        @for $i from 1 through 36
            .letter-#{$i}
                transition-property: left, top
                transition-duration: 0.4s
                animation-delay: 100ms * $i
                left: #{$ll * $column}px
                top: #{$lt * $row}px

                @media (max-width: 480px)
                    $ll: 45
                    $lt: 45
                    left: #{$ll * $column}px
                    top: #{$lt * $row}px

                @media (max-width: 400px)
                    $ll: 45
                    $lt: 45
                    left: #{$ll * $column2}px
                    top: #{$lt * $row2}px

                $column: $column + 1
                @if $i % $perrow == 0
                    $row: $row + 1
                    $column: 0

                $column2: $column2 + 1
                @if $i % $perrow2 == 0
                    $row2: $row2 + 1
                    $column2: 0


        .letter.delay_move
            transition-delay: 0.6s

        .letter_space
            width: 600px
            top: #{$lt * ($row + 1)}px

        .green_letter
            color: #52c636
            &.check
                font-size: 25px

        .player_rotate_btn
            font-size: 25px
            font-weight: bold
            cursor: pointer
            width: 30px
            margin: 0 auto

        .game_timer
            font-weight: bold
            color: #ffffff
            position: relative
            left: -340px
            font-size: 60px
            display: none
            background: #d1212b
            width: 280px
            height: 90px
            z-index: 1
            transition: left 400ms linear
            margin: 0 auto
            text-align: center
            @media (max-width: 480px)
                font-size: 30px
                height: 50px
                width: 180px
            &.game_started
                left: 0
            span
                position: absolute
                z-index: 2
                left: 50%
                transform: translateX(-50%)


        .enigma_letters
            display: none
            position: relative
            width: #{($ll * $perrow) - ($ll - $lw)}px
            height: #{($lt * $row)  - ($lt - $lh)}px
            max-width: 100%
            left: 50%
            transform: translateX(-50%)
            @media (max-width: 480px)
                $ll: 45
                $lt: 45
                $lw: 35
                $lh: 35
                width: #{($ll * $perrow) - ($ll - $lw)}px
                height: #{($lt * $row)  - ($lt - $lh)}px
            @media (max-width: 400px)
                $ll: 45
                $lt: 45
                $lw: 35
                $lh: 35
                width: #{($ll * $perrow2) - ($ll - $lw)}px
                height: #{($lt * $row2)  - ($lt - $lh)}px

        #enigma_word
            padding-bottom: 10px
            margin-bottom: 10px
            border-bottom: 1px solid #000
            clear: both

        .enigma_input
            display: none
            font-size: 40px
            font-weight: bold
            text-transform: uppercase
            color: #000000
            background: #ffffff
            margin: 25pt 0
            width: 100%
            height: 110px
            text-align: center
            position: relative
            span
                &.input_inner
                    display: block
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                    width: 90%

        .enigma_start
            position: absolute
            z-index: 2
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            font-size: 40px
            color: #d1212b
            border: 2px solid
            font-weight: bold
            cursor: pointer
            background: #ffffff
            padding: 20px 40px
            border-radius: 5px

        .enigma_resetbtn
            position: absolute
            width: 80px
            height: 60px
            top: 20px
            left: 10px
            z-index: 4

        .enigma_cover
            position: absolute
            z-index: 2
            background: none
            width: 100%
            height: 100%
            top: 0

        .enigma_end
            display: none

        .final_content
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            background: #ffffff
            padding: 20px 40px
            border-radius: 5px

        .player_name
            padding: 5px 10px
            font-size: 22px
            border-radius: 5px
            border: 1px solid #cecece

        .name_title
            font-size: 18px

        .save_game
            margin-left: 10px
            height: 38px
            position: relative
            border-radius: 5px
            border: 1px solid #35983c
            background: #35983c
            color: #ffffff
            font-size: 18px
            top: -1px

        .final_text
            font-weight: bold
            font-size: 35px
            text-align: center
            background-color: transparent !important

        .final_text ul
            font-size: 25px
            margin-left: 0
            padding-left: 25px

        .container
            max-width: 960px
            margin: 0 auto
            clear: both
            white-space: nowrap
            &.container_sorsolas
                max-width: 100%
                transform: rotate(0deg)
                display: none
            &.brand_bottom
                position: absolute
                bottom: 0
                width: 100%
                transform: rotate(0deg)
                display: none
            .header
                background-image: url(../img/header.jpg)
                height: 122px
                max-width: 100%
                display: block
                position: relative
                background-size: 100%
                span
                    position: absolute
                    top: 50%
                    line-height: 45px
                    transform: translateY(-50%)
                    font-size: 46px
                    color: #fff
                    padding-left: 20px
                    left: 0

        .logo
            position: absolute
            max-width: 202px
            right: 20px
            top: 18px

        #cover
            width: 100%
            height: 100%
            background: rgba(0, 0, 0, 0.6)
            position: absolute
            z-index: 1
            display: none

        #start_timer
            position: absolute
            top: 50%
            left: 50%
            width: 150px
            height: 150px
            transform: translate(-50%, -50%)
            color: #fff
            z-index: 2
            font-size: 70px
            text-align: center
            line-height: 150px
            display: none

        .btn
            padding: 10px 20px
            width: 300px
            margin: 10px auto
            background: #44bad2
            border-radius: 4px
            cursor: pointer
            color: #fff

        .menu, .result
            position: absolute
            width: 400px
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            z-index: 2
            background: #ffffff
            padding: 15px
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7)
            border-radius: 10px
            .player_num_btn
                &.disabled
                    opacity: 0.4

        .result
            display: none
            text-align: center

        .names
            display: none
            .player_name
                padding: 10px 20px
                display: none
                input
                    background: none
                    border: 1px solid #00AAAD
                    padding: 5px 15px
                    width: 100%
                    border-radius: 5px

        .player_container
            height: 100%
            transition: transform 0.7s
            background-color: rgba(0,0,0,0.6)
            overflow: hidden

        .dummy
            position: absolute
            width: 20px
            height: 20px
            background: #ff0000
            top: -100px
            left: -100px
            visibility: hidden

        .dummy_move
            top: -20px
            left: -20px
            transition-property: left, top
            transition-duration: 0.3s
            transition-delay: 0.3s

        .toplist
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            .toplist_inner
                border-radius: 20px
                color: #000
                font-size: 18px
                text-align: center
                .timer
                    position: relative
                    top: auto
                    left: auto
                    text-align: center
                    height: auto
                    width: auto
                    .text
                        position: relative
                        top: auto
                        left: auto
                        transform: none
                .toplist_item
                    width: 900px
                    float: left
                    clear: both
                    padding: 8px 10px
                    text-align: left
                    border-bottom: 2px solid #000
                    &.actual, &.later
                        font-weight: bold
                    .place
                        float: left
                        width: 50px
                    .name
                        float: left
                        width: 720px
                    .game_time
                        float: left
                        width: 100px
                        text-align: right