@import "../../mixins";

#camerastream > article {
    .color-primary {
        font-weight: bold;
    }

    .item.publishing {
        background: #3cb430;
        color: #ffffff;
    }

    .data-name {
        padding: 0 dp(16);
    }
}
