@import "../../mixins";

#hamburger {
    width: 80%;
    max-width: 280px;
    left: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: #323232;
    color: #aaa;
    box-shadow: dp(0) dp(0) dp(5) dp(5) rgba(0, 0, 0, 0.45);
    z-index: 1002;
    &.reverse {
        left: 0;
    }
    .title {
        background-color: #434446;
        display: block;
        /*position: sticky;
        top: 0;*/
        line-height: 1;
        margin: 0;
        padding: dp(10) dp(15);
        border-bottom: dp(3) solid #7d7d7d;
        cursor: pointer;

        h2 {
            color: white;
            font-weight: bold;
        }

        .avatar {
            border: dp(3) solid #999;
            margin: auto;
        }

        .close-main {
            display: inline-block;
            padding: dp(5);
            cursor: pointer;
            @media (pointer: fine) {
                &:hover {
                    color: #7d7d7d !important;
                }
            }

            &:active {
                color: #7d7d7d !important;
            }
        }
    }

    nav {
        padding: dp(10) 0;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            padding: dp(12);
            border-bottom: dp(1) solid rgba(125, 125, 125, 0.2);
            color: inherit;
            cursor: pointer;

            div.icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: dp(42);
                height: dp(42);
                border-radius: 50%;
                background-color: #555;
                color: white;
                font-size: dp(13);
            }

            .data-title {
                flex: 1 1 auto;
                font-size: 115%;
                font-weight: bold;
                margin-left: dp(25);
            }

            &:last-child {
                border: 0;
            }

            @media (pointer: fine) {
                &:hover {
                    background-color: #434446 !important;
                }
            }

            &:active {
                background-color: #434446 !important;
            }
        }
    }
}
