/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#chat > article {
    #chatList {
        .item {
            &.started {
                background: rgba(200,200,200,0.3);
            }
        }
    }
    #chatList, #chatUsersList {

        .more-cont {
            text-align: center;
            padding: 10pt 0;
        }

        .item {
            &:last-child {
                border-bottom: .75pt solid rgba(0,0,0,.15);
            }
            position: relative;
            .info {
                padding: 0 dp(16);
                font-size: 90%;

                .fa-fw {
                    margin-right: dp(4);
                }
            }

            .chevron {
                padding: 0 dp(4);
            }

            &.badge {
                &:after {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background: #f00;
                    border-radius: 50%;
                    top: 10.5pt;
                    left: 10.5pt;
                }
            }
        }
    }

    .search-wrapper {
        padding: 0;
        position: relative;
        cursor: text;

        .icon-before {
            padding: dp(16);
        }

        input {
            width: 100%;
            padding: dp(16) dp(32) dp(16) 0;
            margin: 0;
            border: 0;
        }

        .btn-clear {
            display: none;
            position: absolute;
            right: 0;
            padding: dp(16);
            cursor: pointer;
        }
    }

    .no-result {
        display: none;
        margin-top: dp(32);
        text-align: center;
        font-size: 110%;
    }
}

.floatbadge {
    position: absolute;
    z-index: 10000;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    padding: 10pt;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    border-style: solid;
    border-bottom-left-radius: 5pt;
    border-bottom-right-radius: 5pt;
    border-bottom-width: 2pt;
    border-left-width: 2pt;
    border-right-width: 2pt;
    border-top-width: 0;
    animation: slidein 500ms linear;
    animation-fill-mode: forwards;
}

@keyframes slidein {
    0% {
        top: -200px;
    }
    100% {
        top: 0;
    }
}

.floating_chat_wrapper {
    position: fixed;
    z-index: 100001;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    flex-direction: column;
    #chat-details {
        background-color: transparent;
        .sendmessage {
            &.floating_chat {
                position: absolute;
                bottom: 0;
                z-index: 1000;
            }
        }
        > article {
            position: relative;
            overflow: hidden!important;
            .container {
                z-index: 100;
                position: relative;
                max-height: 80%;
                overflow-y: auto!important;
                height: 100%;
                padding: 0 2vmax 2vmax;
                margin-top: 60px;
                @media screen and (min-width: 780px) {
                    background: rgba(255,255,255,.95);
                }
            }
        }
    }
}
