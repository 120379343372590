@import "../../mixins";

#surveys > article {
    .item {
        .info {
            margin: 0 dp(12);
        }
    }

    .no-result {
        display: none;
        margin-top: dp(32);
        text-align: center;
        font-size: 110%;
    }

    .filledsurvey {
        color: green;
    }

}
