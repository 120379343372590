@import "../../mixins";

#landing {
    > header {
        flex: 1 1 auto;
        display: block;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: white;

        /*&:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: dp(6);
            background-color: rgba(150, 150, 150, 1);
        }*/

        .socket_indicator {
            position: absolute;
            top: 5px;
            right: 5px;
            background: #e52323;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            &.connected {
                background: #359835;
                display: none;
            }
        }

        > section {
            flex: 0 0 auto;
            background-color: rgba(255, 255, 255, 1);
        }
    }

    .landing_elements {
        position: absolute;
        top: 45pt;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-content: stretch;
        align-items: stretch;
        z-index: 1000;

        > div {
            position: relative !important;
            z-index: 1;
            display: none;
            flex: 1 1 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            /* overflow: hidden; */
            overflow-x: hidden;
            &#playerElementBackground {
                position: absolute !important;
            }

            &.countdown_bc {
                position: absolute !important;
                display: flex !important;
                flex-direction: column;
                &.hidden {
                    display: none !important;
                }

                &.cdown_topleft {
                    top: 5pt;
                    @media screen and (min-width: 360px) {
                        top: 10pt;
                    }
                    left: 10pt;
                }
                &.cdown_topright {
                    top: 5pt;
                    @media screen and (min-width: 360px) {
                        top: 10pt;
                    }
                    right: 10pt;
                }
                &.cdown_topcenter {
                    top: 5pt;
                    @media screen and (min-width: 360px) {
                        top: 10pt;
                    }
                    left: 50%;
                    transform: translateX(-50%);
                }

                .bc_button {
                    margin: 0 10pt;
                    .open-broadcast {
                        background-color: transparent !important;
                        cursor: pointer;
                        position: relative;
                        .play-btn-cont {
                            font-size: 70px;
                            position: relative;
                            z-index: 2;
                            .fa-play-circle {
                                position: relative;
                                z-index: 1;
                                filter: drop-shadow(0 0 1px #000);
                            }
                            .icon-bg {
                                background: #ffffff;
                                border-radius: 50%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 50%;
                                height: 50%;
                            }
                        }
                        .live_stream_title {
                            padding: 5pt 10pt 5pt 30pt;
                            position: relative;
                            margin-left: -30px;
                            border-top-right-radius: 5pt;
                            border-bottom-right-radius: 5pt;
                            box-shadow: 1px 1px 2px #000;
                            font-weight: bold;
                        }
                    }
                }
            }
            &.shown {
                display: block;
            }
            .closebutton {
                display: none !important;
            }
            .backbutton {
                display: none !important;
            }
            div {
                &.outer {
                    max-width: 100%;
                }

                &.container {
                    max-width: 100%;
                    margin-top: 0;
                    max-height: 100%;
                    margin-bottom: 0;
                    border-radius: 0;
                    overflow-y: auto;
                }
            }
            &.hero {
                display: block;
                overflow: auto;
                position: absolute !important;
            }
        }
        .stage_cont {
            background: #ffffff;
        }
        .moderate_cont {
            background: #ffffff;
        }

        .translator_buttons {
            z-index: 1000;
            position: absolute !important;
            top: 45pt;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            background: rgba(255,255,255,0.7);
            padding: 10pt;
            border-radius: 5pt;
            .relay_outputs {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .outputs_title {
                    flex: 1 1 100%;
                    text-align: center;
                    font-weight: bold;
                    margin-top: 5pt;
                }
            }
            .relay_inputs {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .inputs_title {
                    flex: 1 1 100%;
                    text-align: center;
                    font-weight: bold;
                    margin-top: 5pt;
                }
            }
            .translator_input_btn, .translator_output_btn {
                background-color: #ffffff;
                &.active {
                    background-color: #2cb570;

                }
                &:hover {
                    background-color: #555555;
                }
            }
        }

        .mute-video-button {
            color: lightgrey;
            width: 50px;
            height: 50px;
            z-index: 100;
            position: absolute;
            bottom: 10px;
            left: 10px;
            cursor: pointer;
            opacity: 0.7;
        }

        .mute-video-button:hover {
            color: grey;
        }

        .trueconf, .trueconf_translator {
            overflow-y: hidden;
            position: relative;

            .closetrueconf_btn {
                position: absolute;
                top: 40pt;
                right: 20pt;
                font-size: 30pt;
                color: #ffffff;
                border: 2px solid;
                border-radius: 50%;
                width: 30pt;
                height: 30pt;
                background: #fff !important;
                margin-bottom: 10pt;

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                cursor: pointer;
            }

            .translator_btn {
                position: absolute;
                top: 40pt;
                left: 20pt;
                z-index: 10;
                font-size: 30pt;
                color: #ffffff;
                cursor: pointer;
            }
            .trueconf_footer {
                //background-color: #282828;
                align-self: stretch;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: stretch;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                pointer-events: none;
                .trueconf-button {
                    position: relative;
                    font-size: 24px;
                    height: 48px;
                    width: 48px;
                    cursor: pointer;
                    pointer-events: none;
                    &.hangup {
                        pointer-events: all;
                        svg {
                            pointer-events: all;
                        }
                    }
                    &:hover {
                        background-color: rgba(158,158,158,.2) !important;
                    }
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        pointer-events: none;
                    }
                }
            }
        }

        .videostream {
            position: relative;
            .translator_btn {
                position: absolute;
                top: 10pt;
                left: 20pt;
                z-index: 10;
                font-size: 30pt;
                color: #ffffff;
                cursor: pointer;
            }

            .stream_actions_cont {
                position: absolute;
                top: 10pt;
                right: 25pt;
                z-index: 10;

                .closebroadcast_btn {
                    font-size: 30pt;
                    color: #ffffff;
                    position: relative;
                    border: 2px solid;
                    border-radius: 50%;
                    width: 30pt;
                    height: 30pt;
                    background: #fff !important;
                    margin-bottom: 10pt;

                    i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    cursor: pointer;
                }

                .broadcast_showmsgboard_btn {
                    font-size: 30pt;
                    color: #ffffff;
                    cursor: pointer;
                }

                .reactions {
                    font-size: 30pt;
                    background: rgba(255,255,255,0.7);
                    border-radius: 10pt;
                    padding: 5pt;
                    .laugh {
                        color: #ffdf14;
                        cursor: pointer;
                    }
                    .clap {
                        color: #ffffff;
                        cursor: pointer;
                    }
                    .reaction {
                        position: relative;
                        width: 40px;
                        height: 50px;
                        transition: font-size 150ms linear;
                        i {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        &.clicked {
                            font-size: 28pt;
                        }
                    }
                }
            }
        }

        .closeweblink_btn {
            font-size: 30pt;
            color: #ffffff;
            position: absolute;
            right: 10px;
            top: 10px;
            border: 2px solid;
            border-radius: 50%;
            width: 30pt;
            height: 30pt;
            background: #fff !important;
            margin-bottom: 10pt;
            display: none;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            cursor: pointer;
        }
    }

    .hero {
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 100%;
        //@media screen and (min-width: 1100px) {
        //    max-width: 85%;
        //}
        height: 100%;
    }

    .bubbles1 {
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 130vw;
        height: 400vh;
        background: url(../img/bubbles1.png) center;
        background-size: cover;
        opacity: 0.6;
        animation: ani 35s linear infinite;
        transform: translateZ(0);
        backface-visibility: hidden;
        &.reverse {
            animation: ani 35s linear infinite reverse;
        }
    }

    .bubbles2 {
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100vw;
        height: 400vh;
        background: url(../img/bubbles2.png) center;
        background-size: cover;
        opacity: 0.4;
        animation: ani 70s linear infinite;
        transform: translateZ(0);
        backface-visibility: hidden;
        &.reverse {
            animation: ani 70s linear infinite reverse;
        }
    }



    @keyframes ani {
        0% {
            transform: translate3d(0, 0, 0);
        }
        50% {
            transform: translate3d(-10%, -50%, 0);
        }
        100% {
            transform: translate3d(10%, -100%, 0);
        }
    }

    @keyframes myFadeInOutUp {
        0% {
            opacity: 0;
            transform: translateY(0);
        }
        50% {
            opacity: 1;
            transform: translateY(-75px);
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(-150px);
        }
    }

    @keyframes myFadeInOutLeft {
        0% {
            opacity: 0;
            transform: translateX(0);
        }
        50% {
            opacity: 1;
            transform: translateX(75px);
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateX(150px);
        }
    }

    @keyframes myFadeInOutBottom {
        0% {
            opacity: 0;
            transform: translateY(0);
        }
        50% {
            opacity: 1;
            transform: translateY(75px);
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(150px);
        }
    }

    @media (print), (prefers-reduced-motion: reduce) {
        .bubbles1,
        .bubbles2 {
            display: none !important;
        }
    }
}

.stream_reactions {
    position: absolute !important;
    top: auto;
    bottom: 40px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    z-index: 1001 !important;
    animation: fadeIn 800ms;
    &.bottom {
        top: auto;
        left: 50%;
        right: auto;
        bottom: 40px;
        transform: translateX(-50%);
    }
    &.top {
        top: 8px;
        left: 50%;
        right: auto;
        bottom: auto;
        transform: translateX(-50%);
    }
    &.left {
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        right: auto;
        bottom: auto;
        .reaction_emoji {
            display: block;
            padding: 2px 0;
        }
        .stream_reactions_cont {
            flex-direction: column;
            .reaction_emoji {
                padding-right: 0;
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    .stream_reactions_cont {
        background: rgba(0,0,0,0.3);
        padding: 10px;
        border-radius: 5px;
        display: flex;
        .reaction_emoji {
            padding-right: 5px;
            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.animated_reaction {
    display: block;
    position: absolute;
    bottom: 30px;
    transform: translateY(0);
    opacity: 1;
    animation: myFadeInOutUp 800ms linear;
    animation-fill-mode: forwards;
    font-size: 28px;
    z-index: 1001;
    pointer-events: none;
    &.left {
        bottom: auto;
        left: 10px;
        animation: myFadeInOutLeft 800ms linear;
        animation-fill-mode: forwards;
    }
    &.top {
        top: 0;
        left: auto;
        animation: myFadeInOutBottom 800ms linear;
        animation-fill-mode: forwards;
    }
}

#software {
    padding: dp(2);
    background-color: #fff;
    color: #555;
    text-align: center;
    font-size: 8pt;
    .software_inner {
        max-width: dp(1280);
        @media screen and (min-width: 1100px) {
            max-width: 85%;
        }
        overflow: hidden;
        margin: 0 auto;
        .sline {
            position: relative;
            display: block;
            margin: 0 auto;
            transform: translateX(0);
            .footer_item {
                float: left;
                max-height: 50px;
                display: inline-block;
                padding: 5px;
                @media screen and (min-width: 1024px) {
                    max-height: 70px;
                }
            }
            div.footer_item {
                height: 50px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                font-size: 12pt;
                @media screen and (min-width: 1024px) {
                    height: 70px;
                }
            }
        }
    }
}


