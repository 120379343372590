@import "../../mixins";

#docs-details {
    embed {
        &.w_quickmenu {
            padding-left: 45px;
            background: #404040;
        }
    }
}
