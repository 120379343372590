@import "../../mixins";

#notes-details > article {
    fieldset {
        padding: 0;
        margin: dp(12) 0;
        border-radius: dp(3);
        border: 0;
        background-color: #eee;

        legend {
            padding: 0 dp(8);
            margin-left: dp(8);
            font-size: 85%;
            color: #777;
        }

        input[type="text"],
        textarea {
            resize: none;
            font-size: 110%;
            border: 0;
            padding: dp(4) dp(16) dp(12) dp(16);
        }

        &.focus {
            //border-color: #777;
            /*legend {
              color: #777;
            }*/
            input[type="text"],
            textarea {
                font-weight: bold;
                color: #333;
            }
        }
    }

    label {
        cursor: pointer;
    }
}
