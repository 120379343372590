@import "../../mixins";

#ondemandvideos > article {
    .color-primary {
        font-weight: bold;
    }

    .data-name {
        padding: 0 dp(16);
    }
}

#ondemandvideos {

}
