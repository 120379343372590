/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/
.speaker_presentation {
    display: none;
    position: relative;
    z-index: 1000;
    margin:0;
    padding: 0;
    flex-basis: 100%;

    #iSpringFrame {
        pointer-events: none;
        width: 100%;
        height: 100%;
        border: none;
    }

    .timer {
        color: #ffffff;
        padding: 10px;
        background: rgba(0,0,0,0.4);
        font-size: 20px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        &.warning {
            background: #caaa4a !important;
        }
        &.finalwarning {
            background: #7c0000 !important;
        }
    }

    .stepper {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        display: flex;

        .prev_frame {
            cursor: pointer;
            color: #ffffff;
            padding: 10px;
            background: rgba(0,0,0,0.4);
            font-size: 20px;
            width: 40px;
            height: 40px;
            position: relative;
            margin: 5pt;
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 15px;
                top: 38%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                border-bottom: 2px solid #ffffff;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 15px;
                top: 62%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                border-bottom: 2px solid #ffffff;
            }
        }

        .next_frame {
            cursor: pointer;
            color: #ffffff;
            padding: 10px;
            background: rgba(0,0,0,0.4);
            font-size: 20px;
            width: 40px;
            height: 40px;
            position: relative;
            margin: 5pt;
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 15px;
                top: 38%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                border-bottom: 2px solid #ffffff;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 15px;
                top: 62%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                border-bottom: 2px solid #ffffff;
            }
        }
    }

}
