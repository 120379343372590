.presentation {
    pointer-events: none;
    background-color: white;
    display: none;
    position: relative;
    z-index: 1000;
    margin:0;
    padding: 0;
    flex-basis: 100%;

    #iSpringFrame_attendee {
        pointer-events: none;
        width: 100%;
        height: 100%;
        border: none;
    }
}