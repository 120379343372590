@import "../../mixins";

#docs > article {
    .item {
        .info {
            margin: 0 dp(12);
        }
    }

    .data-tags {
        display: none;
        padding: 5px 5px 5px 0;
        color: black;
    }

    .data-tags-item {
        position: relative;
        padding: 5px;
        color: black;
        background-color: rgba(0,0,0,.15);
    }

    .search-wrapper {
        padding: 0;
        position: relative;
        cursor: text;

        .icon-before {
            padding: dp(16);
        }

        input {
            width: 100%;
            padding: dp(16) dp(32) dp(16) 0;
            margin: 0;
            border: 0;
        }

        .btn-clear {
            display: none;
            position: absolute;
            right: 0;
            padding: dp(16);
            cursor: pointer;
        }
    }

    .noresults {
        display: none;
        margin-top: dp(32);
        text-align: center;
        font-size: 110%;
    }

    .grouptitle {
        text-align: center;
        margin-top: 15pt;
        &:first-child {
            margin-top: 3pt;
        }
    }
}
