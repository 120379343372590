/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

@import "../../mixins";

#stage > article, #landing .landing_elements .stage_cont > article {
    .item {
        padding: 5pt;
        margin: 10.5pt;

        .info {
            margin: 0 dp(12);
            .issues-title {
                font-style: italic;
            }
            color: black;
        }

        .note_cont {
            padding: 0 10pt 10pt 0;
            color: #555;
        }

        &:last-child {
            border-bottom: dp(1) solid rgba(0, 0, 0, 0.15);
        }

        &.answered {
            background-color: rgba(156, 192, 156, 0.52);
        }

        &.denied {
            background-color: rgba(179, 0, 0, 0.18);
        }

        .answer-btn, .deny-btn {
            background: #ffffff;
        }
    }

    .filter-all, .filter-answered, .filter-denied, .filter-waiting {
        &.bg-primary {
            color: #ffffff;
        }
    }

    .no-result {
        display: none;
        margin: dp(32) 0;
        text-align: center;
        font-size: 110%;
    }
}
