/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

@import "../../mixins";

#chat-details > article {

    .closebutton_chat {
        margin: 10pt 0;
        position: fixed;
        top: 0;
    }

    .name_chat {
        position: sticky;
        top: 0;
        z-index: 10000;
        text-align: center;
        background: rgba(255,255,255,0.8);
        border-radius: 5pt;
        font-size: 16pt;
        padding: 5pt 10pt;
        margin: 0 auto;
    }

    .container {
        margin-bottom: 10px;
        position: relative;
        padding: 2vmax 0;
        &.classic {
            padding: 2vmax 0;
            height: calc(100% - 2vmax);
            @media screen and (min-width: 780px) {
                height: calc(100% - 6vmax);
            }
        }
        &.transparent {
            background: transparent !important;
            box-shadow: none;
        }
    }
    .messages {

        overflow: auto;
        height: 90%;
        padding: 0 2vmax;

        &:after {
            clear: both;
            content: '';
            display: block;
        }
        .msg_date {
            clear: both;
            text-align: center;
            padding: 10px 0;
            margin-bottom: 10px;
            span {
                display: inline-block;
                padding: 5px 20px;
                background: #e1e5e9;
                border-radius: 5px;
            }
        }
        .message_cont {
            width: 90%;
            padding-bottom: 5px;
            float: right;
            .msg {
                float: right;
                border-radius: 5px;
                display: inline-block;
                background: #e8e8e8;
                padding: 10px;
                margin-left: 5pt;
                .small {
                    font-size: 8pt;
                    text-align: right;
                }
                .small_add {
                    font-size: 8pt;
                    text-align: center;
                }
            }
            &.user_added {
                width: 100%;
                text-align: center !important;
                .msg {
                    width: 100%;
                    background: none !important;
                    color: #cccccc !important;
                    text-align: center !important;
                }
            }
            .sender_username {
                font-size: 10pt;
                padding: 5pt 0;
            }
            &:after {
                clear: both;
                content: '';
                display: block;
            }
            &.sender {
                float: left;
                .msg {
                    float: left;
                    background: #f5f5f5;
                    .small {
                        text-align: left;
                    }
                }
                .sender_username {
                    text-align: left;
                }
            }
        }
    }
}

#chat-details {

    #chatAddList {
        overflow: hidden;
        overflow-y: scroll;
        .addlist_inner {
            height: 100%;
            //overflow: auto;
        }
        .item {
            position: relative;

            .info {
                padding: 0 dp(16);
                font-size: 90%;

                .fa-fw {
                    margin-right: dp(4);
                }
            }

            .chevron {
                padding: 0 dp(4);
            }
        }
    }

    fieldset {
        padding: 0;
        margin: dp(12) dp(12) dp(12) 0;
        border-radius: dp(3);
        border: 0;
        background-color: #eee;

        legend {
            padding: 0 dp(8);
            margin-left: dp(8);
            font-size: 85%;
            color: #777;
        }

        input[type="text"],
        textarea {
            resize: none;
            font-size: 110%;
            border: 0;
            padding: dp(4) dp(16) dp(12) dp(16);
        }

        &.focus {
            //border-color: #777;
            /*legend {
              color: #777;
            }*/
            input[type="text"],
            textarea {
                font-weight: bold;
                color: #333;
            }
        }
    }

    .add_user_to_room {

        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.8);
        z-index: 100;

        .add_user_to_room_inner {
            position: absolute;
            bottom: 30pt;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            max-height: 80%;
            display: flex;
            flex-direction: column;
            width: 90%;
            max-width: 500pt;
            background: #ffffff;
            box-shadow: 0 0 10px rgba(0,0,0,0.4);
            border-radius: 5px;
            &.floating {
                bottom: 50pt;
            }
            .close_btn_cont {
                text-align: center;
                padding: 5pt;
            }

            .searchadd-wrapper {
                padding: 0;
                position: relative;
                cursor: text;

                .icon-before {
                    padding: dp(16);
                }

                input {
                    width: 100%;
                    padding: dp(16) dp(32) dp(16) 0;
                    margin: 0;
                    border: 0;
                }

                .btn-clear {
                    display: none;
                    position: absolute;
                    right: 0;
                    padding: dp(16);
                    cursor: pointer;
                }
            }
        }
    }

    label {
        cursor: pointer;
    }

    .sendmessage {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1000;
        &.floating {
            position: absolute;
            bottom: 0;
            z-index: 1000;
        }

        @media screen and (max-width: 780px) {
            &.floating {
                position: relative;
                bottom: auto;
                z-index: 1000;
            }
        }

        .sendmessage_inner {
            @media screen and (min-width: 780px) {
                border-radius: 11.25pt;
                box-shadow: 0 2px 5px -2px rgba(50, 50, 50, .5);
            }
            &.transparent {
                background: rgba(255,255,255,0.4);
                color: #000000;
                button {
                    color: #000;
                    border: 1.5pt solid #000;
                }
            }
            padding: 0 20px;
            max-width: 585pt;
            background: #ffffff;
            margin: 0 auto;

            input {
                font-size: 100%;
                padding: 6pt 12pt;
            }

            .video-chat {
                white-space: nowrap;
            }

            .add_user {
                cursor: pointer;
                flex: 0 0 50px;
                text-align: center;
            }

            .flex {
                .btn {
                    flex-grow: 0;
                }
            }
        }
    }
}
