@import "../../mixins";

#weblinks > article {
    .item {
        cursor: pointer;
    }

    .data-image {
        max-height: 50px;
    }

    .grouptitle {
        text-align: center;
        margin-top: 15pt;
        &:first-child {
            margin-top: 3pt;
        }
    }

    .cover {
        width: 100%;
        max-width: 300px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        text-align: center;
        border-radius: 5px;
        font-size: 30px;
        z-index: 3000;
    }
    .whitebg {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: white;
        top: 0;
        left: 0;
    }
}

#weblinks-details {
    #weblinksIframeId {
        background: #555;
    }
    .stand_viewer {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 107;
        flex-direction: column;

        .close_stand {
            background: #ffffff;
            padding: 10pt;
            text-align: center;
        }

        #stand_viewer {
            width: 100%;
            height: 100%;
            background: #ffffff;
        }
    }
}
