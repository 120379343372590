/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

html {
    direction: ltr;
}

body {
    line-height: 1.2;
    font-size: 9pt;
    @media screen and (min-width: 360px) {
        font-size: 10pt;
    }
    @media screen and (min-width: 425px) {
        font-size: 11pt;
    }
}

/* Preset Dynamic Styles */
.color-primary {
}

.bg-primary {
}

.border-primary {
}

.color-accent {
}

.bg-accent {
}

.border-accent {
}

.text-secondary {
    color: #777
}

.btn {
    $btn-color: #555;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-size: 110%;
    border-radius: dp(4);
    padding: dp(4) dp(8);
    margin: dp(2);
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    border: dp(2) solid $btn-color;
    color: $btn-color;

    i {
        line-height: inherit;
        color: inherit !important;
    }

    @media (pointer: fine) {
        &:hover {
            background-color: $btn-color;
            border-color: $btn-color;
            color: white;
        }
    }

    &:active {
        background-color: $btn-color;
        border-color: $btn-color;
        color: white;
    }

    &.inverted {
        color: white;
        border-color: white;
        background-color: transparent;
        @media (pointer: fine) {
            &:hover {
                border-color: white;
                background-color: white;
                color: black;
            }
        }

        &:active {
            border-color: white;
            background-color: white;
            color: black;
        }
    }

    i + span,
    span + i {
        margin-left: dp(12);
    }
}

[dir="rtl"] {
    direction: rtl;

    .text-left {
        text-align: right
    }

    .text-right {
        text-align: left
    }

    .fa, .fas, .far {
        transform: scaleX(-1)
    }

    .btn {
        i + span,
        span + i {
            margin-left: auto;
            margin-right: dp(12);
        }
    }
}