/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#attendees > article {
    #attendeesList {

        .more-cont {
            text-align: center;
            padding: 10pt 0;
        }

        .item {
            position: relative;
            .info {
                padding: 0 dp(16);
                font-size: 90%;

                .fa-fw {
                    margin-right: dp(4);
                }
            }

            .chevron {
                padding: 0 dp(4);
            }

            &.badge {
                &:after {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background: #f00;
                    border-radius: 50%;
                    top: 10.5pt;
                    left: 10.5pt;
                }
            }
        }
    }

    .search-wrapper {
        padding: 0;
        position: relative;
        cursor: text;

        .icon-before {
            padding: dp(16);
        }

        input {
            width: 100%;
            padding: dp(16) dp(32) dp(16) 0;
            margin: 0;
            border: 0;
        }

        .btn-clear {
            display: none;
            position: absolute;
            right: 0;
            padding: dp(16);
            cursor: pointer;
        }
    }

    .no-result {
        display: none;
        margin-top: dp(32);
        text-align: center;
        font-size: 110%;
    }
}
