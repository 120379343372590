/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#changepass-details > article {
    fieldset {
        padding: 0;
        margin: dp(12) 0;
        border-radius: dp(3);
        border: 0;
        background-color: #eee;

        legend {
            padding: 0 dp(8);
            margin-left: dp(8);
            font-size: 85%;
            color: #777;
        }

        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="tel"],
        textarea {
            resize: none;
            font-size: 110%;
            border: 0;
            padding: dp(4) dp(16) dp(12) dp(16);
        }

        &.focus {
            //border-color: #777;
            //background-color: #f9f9f9;
            /*legend {
              color: #777;
            }*/
            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="tel"],
            textarea {
                font-weight: bold;
                color: #333;
            }
        }

        label .shopasseyepoz {
            padding: 0 15px 5px 0;
            cursor: pointer;
            text-align: right;
            flex: 0 0 auto;
        }
    }
}
